import { Button } from 'primereact/button';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import SidebarPurchaseStatus from '../sidebar-purchase-status';
import { v4 } from 'uuid';

const PurchaseHeaderButtons: React.FC = () => {
  const navigate = useHistory();

  const [sidebarPuchaseStatusVisible, setSidebarPurchaseStatusVisible] =
    useState(false);

  return (
    <>
      <Button
        label="Compras"
        icon="fa-solid fa-list"
        className="mb-2 mr-2"
        onClick={() => navigate.push('/purchase/list')}
      />

      <Button
        label="Compras"
        icon="fa-solid fa-plus"
        className="mb-2 mr-2"
        onClick={() => navigate.push('/purchase/create')}
      />

      <Button
        label="Compras status"
        icon="fa-solid fa-list"
        className="mb-2 mr-2"
        onClick={() => setSidebarPurchaseStatusVisible(true)}
      />

      <Button
        label="Produtos"
        icon="fa-solid fa-list"
        className="mb-2 mr-2"
        onClick={() => navigate.push('/products/list')}
      />

      <Button
        label="Rel. Estoque crítico"
        icon="fa-solid fa-print"
        className="mb-2 mr-2"
        onClick={() => navigate.push('/report/product/replacement')}
      />

      <Button
        label="Rel. Ordens de Compra"
        icon="fa-solid fa-print"
        className="mb-2 mr-2"
        onClick={() => navigate.push('/report/purchase/list')}
      />

      <Button
        label="Entrada"
        icon="fa-solid fa-list"
        className="mb-2 mr-2"
        onClick={() => navigate.push('/input-products/list')}
      />

      <SidebarPurchaseStatus
        key={v4()}
        isOpen={sidebarPuchaseStatusVisible}
        onRequestClose={() => {
          setSidebarPurchaseStatusVisible(false);
        }}
      />
    </>
  );
};

export default PurchaseHeaderButtons;
