import React, { useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { SideBar } from '../../../components/Sidebar';
import { emptyPurchaseSearch, PurchaseSearch } from '../../../types/purchase';
import { FormHandles } from '@unform/core';
import { InputNumber } from '../../../components/Inputs/InputNumber';
import { InputText } from '../../../components/Inputs/InputText';
import { Button } from 'primereact/button';
import useToastContext from '../../../hooks/toast';
import { InputTextArea } from '../../../components/Inputs/InputTextArea';
import { InputPassword } from '../../../components/Inputs/InputPassword';
import * as Yup from 'yup';
import api from '../../../services/api';
import messageRequestError from '../../../utils/messageRequestError';
import getValidationErrors from '../../../utils/getErrorsValidation';

type IRequest = {
  purchase_id: string;
  obs: string;
  password: string;
};

type IProps = {
  isOpen: boolean;
  onRequestClose: () => void;
  purchase: PurchaseSearch;
};

const SidebarPurchaseAllow = ({
  isOpen,
  onRequestClose,
  purchase,
}: IProps): React.ReactElement => {
  const formRef = useRef<FormHandles>(null);
  const toast = useToastContext();

  const [isLoad, setIsLoad] = useState(false);
  const [item, setItem] = useState<IRequest>({
    purchase_id: '',
    obs: '',
    password: '',
  });

  const handleSubmit = async (item: IRequest) => {
    try {
      setIsLoad(true);
      formRef.current?.setErrors({});

      item.purchase_id = purchase.id;

      const schema = Yup.object({
        purchase_id: Yup.string().uuid().required('Informe a ordem de compra'),
        obs: Yup.string(),
        password: Yup.string().required(
          'Informe a senha do usuário para autorizar a ordem de compra',
        ),
      });

      await schema.validate(item, { abortEarly: false });

      await api
        .post('/purchase/allow', {
          data: item,
        })
        .then(({ data }) => {
          if (data) {
            toast(
              'success',
              'Sucesso',
              'Ordem de compra autorizada com sucesso',
            );
            onRequestClose();
          }
        })
        .catch((e: any) => {
          toast('warn', 'Alerta', messageRequestError(e));
        })
        .finally(() => {
          setIsLoad(false);
        });
    } catch (err: any) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        toast('error', 'Error', err.errors[0]);
      } else {
        toast('error', 'Error', err.response?.data?.error);
      }
    } finally {
      setIsLoad(false);
    }
  };

  return (
    <SideBar
      visible={isOpen}
      onHide={() => onRequestClose()}
      position="right"
      style={{ width: '50vw' }}
    >
      <h1>Assinar autorização de compra</h1>
      <Form
        ref={formRef}
        onSubmit={d => handleSubmit(item)}
        className="p-fluid grid formgrid"
        placeholder={''}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
      >
        <div className="field col-2">
          <label htmlFor="codigo">Código</label>
          <InputNumber
            name="codigo"
            value={purchase.codigo || 0}
            placeholder="Ex.: 000"
            disabled
            readOnly
          />
        </div>
        <div className="field col-8">
          <label htmlFor="provider">Fornecedor</label>
          <InputText
            name="provider_name"
            value={purchase.provider_name}
            placeholder="Ex.: Industrias ACME Ltda"
            disabled
            readOnly
          />
        </div>
        <div className="field col-2">
          <label htmlFor="total">Total</label>
          <InputNumber
            name="total"
            value={purchase.total}
            minFractionDigits={2}
            maxFractionDigits={2}
            disabled
            readOnly
          />
        </div>
        <div className="field col-12">
          <label htmlFor="obs">Obs</label>
          <InputTextArea
            name="obs"
            value={item.obs}
            onChange={e => setItem({ ...item, obs: e.currentTarget.value })}
            placeholder="Ex.: Compra feita para a empresa XYZ."
          />
        </div>
        <div className="field col-6">
          <label htmlFor="password">Senha</label>
          <InputPassword
            name="password"
            value={item.password}
            onChange={e =>
              setItem({ ...item, password: e.currentTarget.value })
            }
            feedback={false}
            toggleMask={true}
            placeholder="********"
          />
        </div>
        <div className="field col-3 flex align-items-end justify-content-center">
          <Button
            label="Cancelar"
            type="reset"
            icon="pi pi-times"
            onClick={() => onRequestClose()}
            className="p-button-danger"
            loading={isLoad}
          />
        </div>

        <div className="field col-3 flex align-items-end justify-content-center">
          <Button
            label={'Autorizar'}
            icon="pi pi-check"
            className="p-button-success"
            type="submit"
            loading={isLoad}
            onClick={() => formRef.current?.submitForm()}
          />
        </div>
      </Form>
    </SideBar>
  );
};

export default SidebarPurchaseAllow;
