import { Form } from '@unform/web';
import { MultiSelect } from '../../../../components/Inputs/InputMultSelect';
import PurchaseHeaderButtons from '../../../purchase/header-buttons';
import { useEffect, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import CalendarRange from '../../../../components/Inputs/CalendarRange';
import { Divider } from '../../../../components/Divider';
import { Button } from 'primereact/button';
import {
  emptySearchProductReplacement,
  SearchProductReplacement,
} from './dtos';
import tipoProdutoOpts from '../../../product/types/TipoProdutoOpts';
import formatoProdutoOpts from '../../../product/types/FormatoProdutoOpts';
import producaoProdutoOpts from '../../../product/types/ProducaoProdutoOpts';
import condicaoProdutoOpts from '../../../product/types/CondicaoProdutoOpts';
import IOptionsDTO, { emptyOption } from '../../../business/dtos/IOptionsDTO';
import { InputChecked } from '../../../../components/Inputs/InputChecked';
import api from '../../../../services/api';
import { AxiosError } from 'axios';
import messageRequestError from '../../../../utils/messageRequestError';
import useToastContext from '../../../../hooks/toast';
import Progress from '../../../../components/progress-bar';
import openReports from '../../../../utils/openReports';
import { InputSwitch } from '../../../../components/Inputs/InputSwitch';
import { InputDropDown } from '../../../../components/Inputs/InputDropDown';

const ReportProductReplacement = () => {
  const formRef = useRef<FormHandles>(null);
  const toast = useToastContext();

  const [isLoad, setIsLoad] = useState(false);

  const [filter, setFilter] = useState<SearchProductReplacement>(
    emptySearchProductReplacement,
  );
  const [groups, setGroups] = useState<IOptionsDTO[]>([]);
  const [tags, setTags] = useState<IOptionsDTO[]>([]);
  const [brands, setBrands] = useState<IOptionsDTO[]>([]);

  const loadGroups = async () => {
    setIsLoad(true);
    await api
      .get('/groups')
      .then(({ data }) => {
        if (data) {
          setGroups(
            data.map((i: any) => {
              return {
                value: i.id,
                label: i.title,
              };
            }),
          );
        }
      })
      .catch((e: AxiosError) => {
        toast('warn', 'Alerta', messageRequestError(e));
      })
      .finally(() => {
        setIsLoad(false);
      });
  };
  const loadTags = async () => {
    setIsLoad(true);
    await api
      .get('/tags')
      .then(({ data }) => {
        if (data) {
          setTags(
            data.map((i: any) => {
              return {
                value: i.id,
                label: i.tag,
              };
            }),
          );
        }
      })
      .catch((e: AxiosError) => {
        toast('warn', 'Alerta', messageRequestError(e));
      })
      .finally(() => {
        setIsLoad(false);
      });
  };
  const loadBrands = async () => {
    setIsLoad(true);
    await api
      .get('/brands')
      .then(({ data }) => {
        if (data) {
          setBrands(
            data.map((i: any) => {
              return {
                value: i.id,
                label: i.title,
              };
            }),
          );
        }
      })
      .catch((e: AxiosError) => {
        toast('warn', 'Alerta', messageRequestError(e));
      })
      .finally(() => {
        setIsLoad(false);
      });
  };

  const clearAll = () => {
    setFilter(emptySearchProductReplacement);
  };

  const handleSubmit = async (d: any) => {
    setIsLoad(true);
    await api
      .post('/reports/product/replacement', { data: { ...filter } })
      .then(({ data }) => {
        openReports(
          `${process.env.REACT_APP_SERVER_URL}/files/pdf/${data}`,
          'estoque-critico',
        );
      })
      .catch((e: AxiosError) => {
        toast('warn', 'Alerta', messageRequestError(e));
      })
      .finally(() => {
        setIsLoad(false);
      });
  };

  useEffect(() => {
    Promise.all([loadGroups(), loadTags(), loadBrands()]);
  }, []);

  return (
    <>
      <PurchaseHeaderButtons />
      <Progress isLoad={isLoad} />
      <Form
        ref={formRef}
        onSubmit={d => handleSubmit(d)}
        className="p-fluid grid formgrid card"
        placeholder={''}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
      >
        <div className="field col-12">
          <h3>Relatório de estoque crítico</h3>
        </div>
        <div className="field col-4">
          <label htmlFor="kind">Tipos [{filter.kind.length}]</label>
          <MultiSelect
            name="kind"
            value={filter.kind}
            options={tipoProdutoOpts}
            onChange={e => setFilter({ ...filter, kind: e.value })}
            display="chip"
            placeholder="Selecione..."
          />
        </div>
        <div className="field col-4">
          <label htmlFor="format">Formatos [{filter.format.length}]</label>
          <MultiSelect
            name="format"
            value={filter.format}
            options={formatoProdutoOpts}
            onChange={e => setFilter({ ...filter, format: e.value })}
            display="chip"
            placeholder="Selecione..."
          />
        </div>
        <div className="field col-4">
          <label htmlFor="production">
            Produção [{filter.production.length}]
          </label>
          <MultiSelect
            name="production"
            value={filter.production}
            options={producaoProdutoOpts}
            onChange={e => setFilter({ ...filter, production: e.value })}
            display="chip"
            placeholder="Selecione..."
          />
        </div>
        <div className="field col-3">
          <label htmlFor="expiration">Vencimentos entre</label>
          <CalendarRange
            name="expiration"
            value={filter.expiration}
            placeholder="00/00/00 ~ 00/00/00"
            onChange={e =>
              setFilter({ ...filter, expiration: e.value as Date[] })
            }
          />
        </div>
        <div className="field col-3">
          <label htmlFor="condiction">
            Condições [{filter.condiction.length}]
          </label>
          <MultiSelect
            name="condiction"
            value={filter.condiction}
            options={condicaoProdutoOpts}
            onChange={e => setFilter({ ...filter, condiction: e.value })}
            display="chip"
            placeholder="Selecione..."
          />
        </div>
        <div className="field col-2 mb-1">
          Bloqueado? <br />
          <label htmlFor="block">
            Sim
            <InputChecked
              name="block"
              checked={filter.block}
              onChange={e => setFilter({ ...filter, block: true })}
              className="mx-2 mt-3"
            />
          </label>
          <label htmlFor="block">
            Não
            <InputChecked
              name="block"
              checked={!filter.block}
              onChange={e => setFilter({ ...filter, block: false })}
              className="mx-2 mt-3"
            />
          </label>
        </div>
        <div className="field col-2">
          <label htmlFor="status">
            Status [{filter.pagination.status?.length}]
          </label>
          <MultiSelect
            name="status"
            value={filter.pagination.status}
            options={[
              { value: 'A', label: 'Ativo' },
              { value: 'C', label: 'Cancelado' },
            ]}
            display="chip"
            placeholder="Selecione..."
          />
        </div>
        <div className="field col-2">
          <label htmlFor="is_critical">Mostrar</label>
          <InputDropDown
            name="is_critical"
            value={filter.is_critical}
            onChange={e => setFilter({ ...filter, is_critical: e.value })}
            options={[
              { value: 'A', label: 'Todos' },
              { value: 'C', label: 'Crítico' },
              { value: 'N', label: 'Não crítico' },
            ]}
            placeholder="Selecione..."
          />
        </div>
        <div className="field col-12">
          <label htmlFor="group">
            Grupos/Categorias [{filter.group.length}]
          </label>
          <MultiSelect
            name="group"
            value={filter.group}
            options={groups}
            onChange={e => setFilter({ ...filter, group: e.value })}
            display="chip"
            placeholder="Selecione..."
          />
        </div>
        <div className="field col-12">
          <label htmlFor="tag">Tags [{filter.tag.length}]</label>
          <MultiSelect
            name="tag"
            value={filter.tag}
            options={tags}
            onChange={e => setFilter({ ...filter, tag: e.value })}
            display="chip"
            placeholder="Selecione..."
          />
        </div>
        <div className="field col-12">
          <label htmlFor="brand">Marcas [{filter.brand.length}]</label>
          <MultiSelect
            name="brand"
            value={filter.brand}
            options={brands}
            onChange={e => setFilter({ ...filter, brand: e.value })}
            display="chip"
            placeholder="Selecione..."
          />
        </div>

        <Divider />

        <div className="field col-6">
          <Button
            label="Limpar"
            type="reset"
            icon="pi pi-times"
            onClick={e => clearAll()}
            className="p-button-danger"
            loading={isLoad}
          />
        </div>

        <div className="field col-6">
          <Button
            label="Salvar"
            icon="pi pi-check"
            className="p-button-success"
            type="submit"
            loading={isLoad}
            onClick={() => formRef.current?.submitForm()}
          />
        </div>
      </Form>
    </>
  );
};

export default ReportProductReplacement;
