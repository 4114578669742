import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { useEffect, useRef, useState } from 'react';
import { emptyLinknabio, Linknabio, LinknabioLink } from '../dtos';
import { Form } from '@unform/web';
import CrmMenuTop from '../../crm/top-menu';
import { InputText } from '../../../../components/Inputs/InputText';
import { InputTextArea } from '../../../../components/Inputs/InputTextArea';
import { Button } from 'primereact/button';
import SidebarLink from '../sidebar-link';
import SidebarSocial from '../sidebar-social';
import { Badge } from 'primereact/badge';
import { formatSocialNumber } from '../../../../utils/numbers';
import api from '../../../../services/api';
import { AxiosError } from 'axios';
import messageRequestError from '../../../../utils/messageRequestError';
import useToastContext from '../../../../hooks/toast';
import getValidationErrors from '../../../../utils/getErrorsValidation';

const FormLinknabio = () => {
  const formRef = useRef<FormHandles>(null);
  const toast = useToastContext();

  const user = JSON.parse(localStorage.getItem('@wg:user') as any);

  const [isLoad, setIsLoad] = useState(false);

  const [sidebarLinkVisible, setSidebarLinkVisible] = useState(false);
  const [sidebarSocialVisible, setSidebarSocialVisible] = useState(false);

  const [linknabio, setLinknabio] = useState<Linknabio>(emptyLinknabio);

  const clearAll = () => {
    setLinknabio(emptyLinknabio);
  };

  const loadLnkByBusinessId = async (id: string) => {
    setIsLoad(true);
    await api
      .get(`/mkt/linknabio/main/business/${id}`)
      .then(({ data }) => {
        if (data) {
          setLinknabio(data);
        }
      })
      .catch((e: AxiosError) => {
        toast('warn', 'Alerta', messageRequestError(e));
      })
      .finally(() => {
        setIsLoad(false);
      });
  };

  const deleteLink = async (id: string) => {
    setIsLoad(true);
    await api
      .delete(`/mkt/linknabio/link/${id}`)
      .then(({ data }) => {
        if (data) {
          toast('success', 'Sucesso', 'Link removido com sucesso');
          loadLnkByBusinessId(user.b_id);
        }
      })
      .catch((e: AxiosError) => {
        toast('warn', 'Alerta', messageRequestError(e));
      })
      .finally(() => {
        setIsLoad(false);
      });
  };

  const deleteSocial = async (id: string) => {
    setIsLoad(true);
    await api
      .delete(`/mkt/linknabio/social/${id}`)
      .then(({ data }) => {
        if (data) {
          toast('success', 'Sucesso', 'Link social removido com sucesso');
          loadLnkByBusinessId(user.b_id);
        }
      })
      .catch((e: AxiosError) => {
        toast('warn', 'Alerta', messageRequestError(e));
      })
      .finally(() => {
        setIsLoad(false);
      });
  };

  const mainUrlIsUnique = async (mainUrl: string) => {
    setIsLoad(true);
    formRef.current?.setErrors({});

    await api
      .get(`/mkt/linknabio/main/url-is-unique/${mainUrl}`)
      .then(({ data }) => {
        console.log(data);
        if (!data) {
          formRef.current?.setErrors({
            main_url: 'Já existe uma empresa cadastrada com essa url.',
          });

          toast(
            'warn',
            'Alerta',
            `Já existe uma empresa cadastrada com essa url: [${mainUrl}]`,
          );
        }
      })
      .catch((e: AxiosError) => {
        toast('warn', 'Alerta', messageRequestError(e));
      })
      .finally(() => {
        setIsLoad(false);
      });
  };

  const handleSubmit = async (d: any) => {
    setIsLoad(true);
    formRef.current?.setErrors({});

    try {
      const schema = Yup.object({
        name: Yup.string().required('Informe o nome da empresa'),
        main_url: Yup.string().required('Informe o link único da sua empresa'),
      });
      await schema.validate(linknabio, { abortEarly: false });

      await api
        .post('/mkt/linknabio/main', {
          data: {
            header: {
              id: linknabio.id,
              main_url: linknabio.main_url,
              name: linknabio.name,
              image_url: linknabio.image_url,
              google_ana: linknabio.google_ana,
              google_tag: linknabio.google_tag,
              facebook_pix: linknabio.facebook_pix,
              background_is: linknabio.background_is,
              bkg_image_url: linknabio.bkg_image_url,
              str_style: linknabio.str_style,
            },
            links: linknabio.links.map(i => {
              return {
                id: i.id,
                pos: i.pos,
                title: i.title,
                image_url: i.image_url,
                url: i.url,
                btn_background: i.btn_background,
                btn_border: i.btn_border,
                btn_color: i.btn_color,
              };
            }),
            social: linknabio.social.map(i => {
              return {
                id: i.id,
                icon: i.icon,
                title: i.title,
                url: i.url,
              };
            }),
          },
        })
        .then(({ data }) => {
          if (data) {
            toast('success', 'Sucesso', 'Salvo com sucesso.');
          }
        })
        .catch((e: AxiosError) => {
          toast('warn', 'Alerta', messageRequestError(e));
        })
        .finally(() => {
          setIsLoad(false);
        });
    } catch (err: any) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        toast('error', 'Error', err.errors[0]);
      } else {
        toast('error', 'Error', err.response?.data?.error);
      }
    } finally {
      setIsLoad(false);
    }
  };

  useEffect(() => {
    if (user.b_id) {
      loadLnkByBusinessId(user.b_id);
    }
  }, [, sidebarLinkVisible, sidebarSocialVisible]);

  return (
    <>
      <CrmMenuTop />
      <div className="card">
        <Form
          ref={formRef}
          onSubmit={d => handleSubmit(d)}
          className="p-fluid grid formgrid"
          placeholder={''}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        >
          <div className="field col-12">
            <h3>Link na Bio</h3>
          </div>
          <div className="field col-7">
            <label htmlFor="name">Nome da empresa</label>
            <InputText
              name="name"
              value={linknabio.name}
              onChange={e =>
                setLinknabio({ ...linknabio, name: e.currentTarget.value })
              }
              placeholder="Ex.: Industrias ACME Ltda"
            />
          </div>
          <div className="field col-3">
            <label htmlFor="main_url">Link único</label>
            <InputText
              name="main_url"
              value={linknabio.main_url}
              onChange={e =>
                setLinknabio({ ...linknabio, main_url: e.currentTarget.value })
              }
              placeholder="Ex.: @industrias.acme"
              onBlur={e => mainUrlIsUnique(e.currentTarget.value)}
            />
          </div>
          <div className="field col-2">
            <label htmlFor="views">Visualizações</label>
            <InputText
              name="views"
              value={linknabio.views}
              disabled
              readOnly
              className="surface-200"
              placeholder="000"
            />
          </div>
          <div className="field col-4">
            <label htmlFor="google_ana">Google Analycts</label>
            <InputTextArea
              name="google_ana"
              value={linknabio.google_ana}
              onChange={e =>
                setLinknabio({
                  ...linknabio,
                  google_ana: e.currentTarget.value,
                })
              }
              placeholder="Ex.: Cole o código aqui"
            />
          </div>
          <div className="field col-4">
            <label htmlFor="google_tag">Google Tags</label>
            <InputTextArea
              name="google_tag"
              value={linknabio.google_tag}
              onChange={e =>
                setLinknabio({
                  ...linknabio,
                  google_tag: e.currentTarget.value,
                })
              }
              placeholder="Ex.: Cole o código aqui"
            />
          </div>
          <div className="field col-4">
            <label htmlFor="facebook_pix">Facebook pixel</label>
            <InputTextArea
              name="facebook_pix"
              value={linknabio.facebook_pix}
              onChange={e =>
                setLinknabio({
                  ...linknabio,
                  facebook_pix: e.currentTarget.value,
                })
              }
              placeholder="Ex.: Cole o código aqui"
            />
          </div>

          <div className="field col-8">
            <Button
              label="Links"
              icon="fa-solid fa-chain"
              aria-label="add"
              className="p-button-rounded mb-2 mr-2"
              type="button"
              onClick={() => setSidebarLinkVisible(true)}
            />
            {linknabio.links
              .sort((a, b) => a.pos - b.pos)
              .map((link, index) => {
                return (
                  <>
                    <div className="card bg-gray-100 mb-2 p-0" draggable>
                      <div className="flex justify-content-between align-items-center flex-wrap">
                        <img
                          src={`${process.env.REACT_APP_SERVER_URL}/files/img/${link.image_url}`}
                          width={64}
                          style={{ borderRadius: 8 }}
                        />
                        <h5>{link.title.slice(0, 32)}</h5>
                        <div>
                          <i
                            className="fa-solid fa-eye p-overlay-badge text-black-alpha-50 mr-5"
                            style={{ fontSize: '2rem' }}
                          >
                            <Badge
                              value={formatSocialNumber(link.clicks || 0)}
                              severity="danger"
                            ></Badge>
                          </i>

                          <Button
                            icon="fa-solid fa-trash"
                            aria-label="add"
                            className="p-button-rounded p-button-danger mr-4"
                            type="button"
                            onClick={() => deleteLink(link.id)}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
          </div>

          <div className="field col-4">
            <Button
              label="Social"
              icon="fa-solid fa-share"
              aria-label="add"
              className="p-button-rounded mb-2 mr-2"
              type="button"
              onClick={() => setSidebarSocialVisible(true)}
            />

            {linknabio.social.map((social, index) => {
              return (
                <>
                  <div
                    key={social.id}
                    className="card bg-blue-100 mb-2 p-0"
                    draggable
                  >
                    <div className="flex justify-content-between align-items-center flex-wrap">
                      <i
                        className={`${social.icon} text-black-alpha-50 ml-4`}
                        style={{ fontSize: '2rem' }}
                      ></i>
                      <h6>{social.title.slice(0, 24)}</h6>
                      <div>
                        <Button
                          icon="fa-solid fa-trash"
                          aria-label="add"
                          className="p-button-rounded p-button-danger mr-4"
                          type="button"
                          onClick={() => deleteSocial(social.id)}
                        />
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>

          <div className="field col-6">
            <Button
              label="Limpar"
              type="reset"
              icon="pi pi-times"
              onClick={e => clearAll()}
              className="p-button-danger"
              loading={isLoad}
            />
          </div>

          <div className="field col-6">
            <Button
              label="Salvar"
              icon="pi pi-check"
              className="p-button-success"
              type="submit"
              loading={isLoad}
              onClick={() => formRef.current?.submitForm()}
            />
          </div>
        </Form>
      </div>
      <SidebarLink
        linkId={linknabio.id}
        isOpen={sidebarLinkVisible}
        onRequestClose={() => setSidebarLinkVisible(false)}
      />
      <SidebarSocial
        linkId={linknabio.id}
        isOpen={sidebarSocialVisible}
        onRequestClose={() => setSidebarSocialVisible(false)}
      />
    </>
  );
};
export default FormLinknabio;
