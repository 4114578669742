import { ReactNode } from 'react';
import { OrderServiceProvider } from './OrderServiceContext';
import { ParamsConsignedProvider } from './ParamsConsignedContext';
import { OrderProvider } from './OrderContext';
import { PurchaseProvider } from './PurchaseContext';

const ContextProviders = ({ children }: { children: ReactNode }) => {
  return (
    <PurchaseProvider>
      <OrderServiceProvider>
        <ParamsConsignedProvider>
          <OrderProvider>{children}</OrderProvider>
        </ParamsConsignedProvider>
      </OrderServiceProvider>
    </PurchaseProvider>
  );
};

export default ContextProviders;
