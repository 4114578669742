export function formatSocialNumber(valor: number): string {
  if (valor >= 1_000_000) {
    // Formata para milhões (M)
    const valorFormatado = (valor / 1_000_000).toFixed(1);
    return `${valorFormatado}M`;
  } else if (valor >= 1000) {
    // Formata para milhares (k)
    const valorFormatado = (valor / 1000).toFixed(1);
    return `${valorFormatado}k`;
  } else {
    // Retorna o valor original como string
    return valor.toString();
  }
}
