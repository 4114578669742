import { useContext } from 'react';
import { OrderServiceContext } from '../context/OrderServiceContext';
import { PurchaseContext } from '../context/PurchaseContext';

export function usePurchase() {
  const context = useContext(PurchaseContext);
  if (!context) {
    throw new Error('Falha ao acessar o hooks de compras context!');
  }
  return context;
}
