import React from 'react';
import { SideBar } from '../../../components/Sidebar';
import AddProduct from '../Create';

interface FastFormProps {
  isOpen: boolean;
  onRequestClose: () => void;
}

export function FastFormProduct({ isOpen, onRequestClose }: FastFormProps) {
  return (
    <SideBar
      visible={isOpen}
      position="right"
      onHide={() => {
        onRequestClose();
      }}
      style={{ width: '60vw' }}
    >
      <AddProduct />
    </SideBar>
  );
}
