import { v4 } from 'uuid';

export type LinknabioSocial = {
  id: string;
  link_id?: string;
  icon: string;
  title: string;
  url: string;
};

export const emptyLinknabioSocial: LinknabioSocial = {
  id: v4(),
  icon: '',
  title: '',
  url: '',
};

export type LinknabioLink = {
  id: string;
  link_id?: string;
  pos: number;
  title: string;
  image_url: string;
  url: string;
  btn_background: string;
  btn_border: string;
  btn_color: string;
  clicks?: number;
};

export const emptyLinknabioLink: LinknabioLink = {
  id: v4(),
  link_id: '',
  pos: 0,
  title: '',
  image_url: '',
  url: '',
  btn_background: '',
  btn_border: '',
  btn_color: '',
};

export type Linknabio = {
  id: string;
  main_url: string;
  name: string;
  image_url: string;
  google_ana: string;
  google_tag: string;
  facebook_pix: string;
  background_is: string;
  bkg_image_url: string;
  str_style: string;
  views: number;
  links: LinknabioLink[];
  social: LinknabioSocial[];
};

export const emptyLinknabio: Linknabio = {
  id: v4(),
  main_url: '',
  name: '',
  image_url: '',
  google_ana: '',
  google_tag: '',
  facebook_pix: '',
  background_is: '',
  bkg_image_url: '',
  str_style: '',
  views: 0,
  links: [],
  social: [],
};

export const mockLinknabio: Linknabio = {
  id: v4(),
  main_url: '',
  name: '',
  image_url: '',
  google_ana: '',
  google_tag: '',
  facebook_pix: '',
  background_is: 'css',
  bkg_image_url: '',
  str_style: '',
  views: 0,
  links: [
    {
      id: v4(),
      pos: 1,
      title: 'link número 1',
      image_url:
        'https://i1.sndcdn.com/artworks-000546870324-ubtsqs-t500x500.jpg',
      url: '',
      btn_background: '',
      btn_border: '',
      btn_color: '',
      clicks: 1500844,
    },
    {
      id: v4(),
      pos: 2,
      title: 'link número 2',
      image_url:
        'https://i1.sndcdn.com/artworks-000546870324-ubtsqs-t500x500.jpg',
      url: '',
      btn_background: '',
      btn_border: '',
      btn_color: '',
      clicks: 10,
    },
  ],
  social: [
    {
      id: v4(),
      icon: 'fa-brands fa-facebook',
      title: 'facebook',
      url: '',
    },
    {
      id: v4(),
      icon: 'fa-brands fa-instagram',
      title: 'instagram',
      url: '',
    },
  ],
};
