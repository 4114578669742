import { endOfMonth, startOfMonth } from 'date-fns';
import { IPagination } from '../../../../../types/pagination';
import formatoProdutoOpts from '../../../../product/types/FormatoProdutoOpts';
import producaoProdutoOpts from '../../../../product/types/ProducaoProdutoOpts';
import tipoProdutoOpts from '../../../../product/types/TipoProdutoOpts';
import condicaoProdutoOpts from '../../../../product/types/CondicaoProdutoOpts';
import { EnumCritical } from '../../../../../enum/EnumCritical';
import { EnumStatus } from '../../../../../enum/EnumStatus';

export type SearchProductReplacement = {
  is_critical: string;
  kind: string[];
  format: string[];
  production: string[];
  expiration: Date[];
  condiction: string[];
  group: string[];
  tag: string[];
  brand: string[];
  block: boolean | null;
  pagination: IPagination;
};

export const emptySearchProductReplacement: SearchProductReplacement = {
  is_critical: EnumCritical.ALL,
  kind: [...tipoProdutoOpts.map(i => i.value)],
  format: [...formatoProdutoOpts.map(i => i.value)],
  production: [...producaoProdutoOpts.map(i => i.value)],
  expiration: [],
  condiction: [...condicaoProdutoOpts.map(i => i.value)],
  group: [],
  tag: [],
  brand: [],
  block: false,
  pagination: {
    page: 1,
    perPage: 10000,
    status: ['A', 'C'],
  },
};
