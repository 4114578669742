import { FormHandles } from '@unform/core';
import { useEffect, useRef, useState } from 'react';
import { usePurchase } from '../../../hooks/usePurchase';
import { emptyProductPriceSearch } from '../../../pages/order/types/EmptyProductPriceSearch';
import { IProductPriceSearch } from '../../../pages/product/dtos/IProductDTO';
import useToastContext from '../../../hooks/toast';
import { InputAutoComplete } from '../../Inputs/InputAutoComplete';
import { InputText } from '../../Inputs/InputText';
import { InputNumber } from '../../Inputs/InputNumber';
import { Button } from 'primereact/button';
import { validate } from 'uuid';
import { Divider } from '../../Divider';
import { DataTable } from '../../DataTable';
import { Column } from 'primereact/column';
import renderColumnPosition from '../../DataTableColumns/RenderColumnPosition';
import {
  AutoCompleteCompleteMethodParams,
  AutoCompleteSelectParams,
} from 'primereact/autocomplete';
import api from '../../../services/api';
import messageRequestError from '../../../utils/messageRequestError';
import { emptyPurchaseItem, PurchaseItem } from '../../../types/purchase';
import { Form } from '@unform/web';
import getValidationErrors from '../../../utils/getErrorsValidation';
import * as Yup from 'yup';
import renderColumnDecimal from '../../DataTableColumns/RenderColumnDecimal';
import renderColumnStatus from '../../DataTableColumns/RenderColumnStatus';
import renderColumnFlag from '../../DataTableColumns/RenderColumnFlag';
import formatDecimal from '../../../utils/numbers/FormatDecimal';
import { DataTableSelectParams } from 'primereact/datatable';

const InputRowPurchase = () => {
  const formRef = useRef<FormHandles>(null);
  const toast = useToastContext();
  const { purchase, item, setItem, addItem, remItem, clearItem } =
    usePurchase();

  const [isLoad, setIsLoad] = useState(false);
  const [keyword, setKeyword] = useState<string | number>('');
  const [product, setProduct] = useState<IProductPriceSearch>(
    emptyProductPriceSearch,
  );
  const [products, setProducts] = useState<IProductPriceSearch[]>([]);

  const handleSelectProduct = (e: AutoCompleteSelectParams) => {
    const selectProduct: IProductPriceSearch = e.value;
    if (!validate(selectProduct.id)) {
      toast('warn', 'Alerta', 'Falha ao selecionar o produto!');
      return;
    }
    setItem({
      ...emptyPurchaseItem,
      id: purchase.purchaseItems.length + 1,
      product_id: selectProduct.id,
      product: selectProduct,
      unity: selectProduct.unity || 'UND',
      vlr: Number(selectProduct.cost_billing),
    });
    setProduct(selectProduct);
  };

  const handleClearRow = () => {
    setProduct(emptyProductPriceSearch);
    setProducts([]);
  };

  const handleAddItem = async (item: PurchaseItem) => {
    try {
      setIsLoad(true);
      formRef.current?.setErrors({});
      const schema = Yup.object({
        id: Yup.number().positive().required('Informe um id válido'),
        product_id: Yup.string().uuid().required('Informe um produto'),
        unity: Yup.string().required('Informe uma unidade'),
        qnt: Yup.number()
          .positive('Informe uma quantidade maior que zero')
          .required('Informe uma quantidade'),
        vlr: Yup.number().required('Informe um valor de custo'),
      });
      await schema.validate(item, { abortEarly: false });
      addItem(item);
      handleClearRow();
    } catch (err: any) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        toast('error', 'Error', err.errors[0]);
      } else {
        toast('error', 'Error', err.response?.data?.error);
      }
    } finally {
      setIsLoad(false);
    }
  };

  const handleRemItem = (id: number) => {
    remItem(id);
  };

  const handleCheckItem = (id: number) => {
    const checkItem = purchase.purchaseItems.find(i => i.id === id);
    if (checkItem) {
      checkItem.able = !checkItem.able;
      addItem(checkItem);
    }
  };

  const handleRowSelect = (item: DataTableSelectParams) => {
    if (item.data) {
      setProduct({ ...item.data.product });
      setItem(item.data);
    }
  };

  const itemTemplateProduct = (i: IProductPriceSearch) => {
    return (
      <div className="country-item">
        <div>{`${i.title} # ${i.sku}`}</div>
      </div>
    );
  };

  const searchProduct = async (e: AutoCompleteCompleteMethodParams) => {
    if (e.query.length >= 3) {
      api
        .post('/products/v2/prices/search', {
          data: {
            keyword: e.query,
            show: { page: 1, perPage: 100, status: 'A' },
          },
        })
        .then(({ data }) => {
          if (data && data.length > 0) {
            setProducts(
              data.map((i: IProductPriceSearch) => {
                return {
                  ...i,
                  value: i.id,
                  label: i.title,
                };
              }),
            );
          }
        })
        .catch((e: any) => {
          toast('warn', 'Alerta', messageRequestError(e));
        });
    }
  };

  const calculateRow = () => {
    if (item) {
      setItem({
        ...item,
        total:
          (Number(item.vlr) + Number(item.add) - Number(item.desc)) *
          Number(item.qnt),
      });
    }
  };

  const renderColumnMenu = (item: PurchaseItem) => {
    return (
      <>
        <Button
          type="button"
          icon="fa-solid fa-check"
          onClick={() => handleCheckItem(item.id)}
          className="p-button-info p-button-rounded mr-4"
        />
        <Button
          type="button"
          icon="fa-solid fa-xmark"
          onClick={() => handleRemItem(item.id)}
          className="p-button-danger p-button-rounded"
        />
      </>
    );
  };

  useEffect(() => {
    calculateRow();
  }, [item.qnt, item.vlr, item.add, item.desc]);

  return (
    <>
      <Form
        ref={formRef}
        onSubmit={() => {
          handleAddItem(item);
        }}
        className="p-fluid grid formgrid"
      >
        <div className="field col-5 ">
          <label htmlFor="product_id">Produto</label>
          <InputAutoComplete
            name="product_keyword"
            value={product}
            suggestions={products}
            field="title"
            completeMethod={e => searchProduct(e)}
            onSelect={e => handleSelectProduct(e)}
            onChange={e => setProduct({ ...product, title: e.value })}
            itemTemplate={e => itemTemplateProduct(e)}
            placeholder={`Buscar produto...`}
            disabled={!validate(purchase.provider_id)}
          />
        </div>

        <div className="field col-1">
          <label htmlFor="unity">Unidade</label>
          <InputText
            name="unity"
            value={item.unity}
            onChange={e => setItem({ ...item, unity: e.currentTarget.value })}
            placeholder="Ex.: UND"
            disabled={!validate(purchase.provider_id)}
          />
        </div>

        <div className="field col-1">
          <label htmlFor="qnt">Qnt</label>
          <InputNumber
            name="qnt"
            value={item.qnt}
            onChange={e => setItem({ ...item, qnt: Number(e.value) })}
            mode="decimal"
            minFractionDigits={2}
            maxFractionDigits={2}
            placeholder="0,00"
            disabled={!validate(purchase.provider_id)}
          />
        </div>

        <div className="field col-1">
          <label htmlFor="vlr">Valor</label>
          <InputNumber
            name="vlr"
            value={item.vlr}
            onChange={e => setItem({ ...item, vlr: Number(e.value) })}
            mode="decimal"
            minFractionDigits={2}
            maxFractionDigits={2}
            placeholder="0,00"
            disabled={!validate(purchase.provider_id)}
          />
        </div>

        <div className="field col-1">
          <label htmlFor="add">Adic.</label>
          <InputNumber
            name="add"
            value={item.add}
            onChange={e => setItem({ ...item, add: Number(e.value) })}
            mode="decimal"
            minFractionDigits={2}
            maxFractionDigits={2}
            placeholder="0,00"
            disabled={!validate(purchase.provider_id)}
          />
        </div>

        <div className="field col-1">
          <label htmlFor="desc">Desc.</label>
          <InputNumber
            name="desc"
            value={item.desc}
            onChange={e => setItem({ ...item, desc: Number(e.value) })}
            mode="decimal"
            minFractionDigits={2}
            maxFractionDigits={2}
            placeholder="0,00"
            disabled={!validate(purchase.provider_id)}
          />
        </div>

        <div className="field col-1">
          <label htmlFor="total">Total</label>
          <InputNumber
            name="total"
            value={item.total}
            mode="decimal"
            minFractionDigits={2}
            maxFractionDigits={2}
            placeholder="0,00"
            disabled
            className="surface-200"
          />
        </div>

        <div className="field col-1 flex align-items-end justify-content-center">
          <Button
            type="submit"
            loading={isLoad}
            disabled={!validate(item.product_id || '')}
            className="p-button-success w-6"
            icon="fa-solid fa-plus"
            onClick={e => handleAddItem(item)}
          />
        </div>
      </Form>

      <Divider />

      <DataTable
        value={purchase.purchaseItems}
        responsiveLayout="scroll"
        paginator
        rows={10}
        rowsPerPageOptions={[10, 50, 200]}
        size="small"
        emptyMessage="Produtos não informados..."
        selectionMode="single"
        onRowSelect={e => handleRowSelect(e)}
        className="field col-12 p-0"
      >
        <Column
          header="#"
          className="my-0 py-1"
          body={renderColumnPosition}
          align={'center'}
          sortable
        ></Column>
        <Column
          header="Produto"
          field="product.title"
          align={'left'}
          className="my-0 py-1"
        ></Column>
        <Column
          header="Estoque"
          field="product.stock"
          align={'center'}
          body={row => renderColumnDecimal(row.product.stock || 0)}
          className="my-0 py-1"
        ></Column>
        <Column
          header="Qnt."
          field="qnt"
          align={'center'}
          body={row => renderColumnDecimal(row.qnt)}
          footer={() =>
            formatDecimal(
              purchase.purchaseItems.reduce((a, b) => {
                a += Number(b.qnt);
                return a;
              }, 0),
            )
          }
          className="my-0 py-1"
        ></Column>
        <Column
          header="Vlr."
          field="vlr"
          align={'center'}
          body={row => renderColumnDecimal(row.vlr)}
          className="my-0 py-1"
        ></Column>
        <Column
          header="Add."
          field="add"
          align={'center'}
          body={row => renderColumnDecimal(row.add)}
          footer={() =>
            formatDecimal(
              purchase.purchaseItems.reduce((a, b) => {
                a += Number(b.add);
                return a;
              }, 0),
            )
          }
          className="my-0 py-1"
        ></Column>
        <Column
          header="Desc."
          field="desc"
          align={'center'}
          body={row => renderColumnDecimal(row.desc)}
          footer={() =>
            formatDecimal(
              purchase.purchaseItems.reduce((a, b) => {
                a += Number(b.desc);
                return a;
              }, 0),
            )
          }
          className="my-0 py-1"
        ></Column>
        <Column
          header="Total"
          field="total"
          align={'center'}
          body={row => renderColumnDecimal(row.total)}
          footer={() =>
            formatDecimal(
              purchase.purchaseItems.reduce((a, b) => {
                a += Number(b.total);
                return a;
              }, 0),
            )
          }
          className="my-0 py-1"
        ></Column>
        <Column
          header="Disponível"
          field="able"
          body={row => renderColumnFlag(row.able)}
          align={'center'}
          className="my-0 py-1"
        ></Column>
        <Column
          header="Status"
          field="status"
          align={'center'}
          body={row => renderColumnStatus(row)}
          className="my-0 py-1"
        ></Column>
        <Column
          header="*"
          body={row => renderColumnMenu(row)}
          align={'center'}
          className="my-0 py-1"
        ></Column>
      </DataTable>
      <Divider />
    </>
  );
};

export default InputRowPurchase;
