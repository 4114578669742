import { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { SideBar } from '../../../../components/Sidebar';
import { FormHandles } from '@unform/core';
import useToastContext from '../../../../hooks/toast';
import { emptyLinknabioSocial, LinknabioSocial } from '../dtos';
import api from '../../../../services/api';
import { emptyPagination, IPagination } from '../../../../types/pagination';
import { AxiosError } from 'axios';
import messageRequestError from '../../../../utils/messageRequestError';
import getValidationErrors from '../../../../utils/getErrorsValidation';
import { Button } from 'primereact/button';
import { InputText } from '../../../../components/Inputs/InputText';
import { Form } from '@unform/web';
import { Divider } from '../../../../components/Divider';
import { DataTable } from '../../../../components/DataTable';
import renderColumnPosition from '../../../../components/DataTableColumns/RenderColumnPosition';
import { Column } from 'primereact/column';
import { InputIcons } from '../../../../components/Inputs/InputIcons';
import { v4 } from 'uuid';

type Iprops = {
  linkId?: string;
  isOpen: boolean;
  onRequestClose: () => void;
};

const SidebarSocial = ({ linkId, isOpen, onRequestClose }: Iprops) => {
  const formRef = useRef<FormHandles>(null);
  const uploadRef = useRef<any>(null);
  const toast = useToastContext();

  const [isLoad, setIsLoad] = useState(false);
  const [link, setLink] = useState<LinknabioSocial>(emptyLinknabioSocial);
  const [links, setLinks] = useState<LinknabioSocial[]>([]);

  const handleSelectLink = (item: LinknabioSocial) => {
    if (item) {
      setLink(item);
    }
  };

  const handleDeleteLink = async (id: string) => {
    setIsLoad(true);
    await api
      .delete(`/mkt/linknabio/social/${id}`)
      .then(({ data }) => {
        if (data) {
          toast('sucess', 'Sucesso', 'Item removido com sucesso.');
          loadLinks(emptyPagination);
        }
      })
      .catch((e: AxiosError) => {
        toast('warn', 'Alerta', messageRequestError(e));
      })
      .finally(() => {
        setIsLoad(false);
      });
  };

  const loadLinks = async (pagination: IPagination) => {
    setIsLoad(true);
    await api
      .post('/mkt/linknabio/social/list', { pagination })
      .then(({ data }) => {
        if (data) {
          setLinks([...data]);
        }
      })
      .catch((e: AxiosError) => {
        toast('warn', 'Alerta', messageRequestError(e));
      })
      .finally(() => {
        setIsLoad(false);
      });
  };

  const handleSubmit = async (d: any) => {
    try {
      setIsLoad(true);
      formRef.current?.setErrors({});
      const schema = Yup.object({
        icon: Yup.string().required('Informe o icone do link'),
        title: Yup.string().required('Informe um título para o link'),
        url: Yup.string()
          .url('Informe no formato url com http:// ou https://')
          .required('Informe um link'),
      });
      await schema.validate(link, { abortEarly: false });

      link.id = v4();
      link.link_id = linkId;

      await api
        .post('/mkt/linknabio/social', {
          data: {
            id: link.id,
            link_id: link.link_id,
            icon: link.icon,
            title: link.title,
            url: link.url,
          },
        })
        .then(({ data }) => {
          if (data) {
            toast('success', 'Sucesso', 'Registro criado com sucesso');
            loadLinks(emptyPagination);
            clearAll();
          }
        })
        .catch((e: any) => {
          toast('warn', 'Alerta', messageRequestError(e));
        })
        .finally(() => {
          setIsLoad(false);
        });
    } catch (err: any) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        toast('error', 'Error', err.errors[0]);
      } else {
        toast('error', 'Error', err.response?.data?.error);
      }
    } finally {
      setIsLoad(false);
    }
  };

  const clearAll = async () => {
    setLink(emptyLinknabioSocial);
  };

  const imageBodyTemplate = (link: LinknabioSocial) => {
    return (
      <i
        className={`${link.icon} text-black-alpha-50 ml-4`}
        style={{ fontSize: '2rem' }}
      ></i>
    );
  };

  const renderButtons = (link: LinknabioSocial) => {
    return (
      <>
        <Button
          key={link.id}
          icon="fa-solid fa-trash"
          className="p-button-danger"
          type="button"
          loading={isLoad}
          onClick={() => handleDeleteLink(link.id)}
        />
      </>
    );
  };

  useEffect(() => {
    loadLinks(emptyPagination);
  }, []);

  return (
    <SideBar
      visible={isOpen}
      onHide={() => {
        onRequestClose();
      }}
      position="right"
      style={{ width: '60vw' }}
    >
      <Form
        ref={formRef}
        onSubmit={d => handleSubmit(d)}
        className="p-fluid grid formgrid"
        placeholder={''}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
      >
        <div className="field col-12">
          <h1>{link.icon && <i className={link.icon}></i>} Social </h1>
        </div>
        <div className="field col-4">
          <label htmlFor="icon">Ícone</label>
          <InputIcons
            name="icon"
            value={link.icon}
            onChange={e => setLink({ ...link, icon: e.value })}
            placeholder="Selecione..."
          />
        </div>
        <div className="field col-8">
          <label htmlFor="title">Título</label>
          <InputText
            name="title"
            value={link.title}
            onChange={e => setLink({ ...link, title: e.currentTarget.value })}
            placeholder="Ex.: Instagram"
          />
        </div>
        <div className="field col-12">
          <label htmlFor="url">Link</label>
          <InputText
            name="url"
            type="url"
            value={link.url}
            onChange={e => setLink({ ...link, url: e.currentTarget.value })}
            placeholder="Ex.: http://www.seulink.com.br"
          />
        </div>
        <Divider />
        <div className="field col-12 md:col-6">
          <Button
            label="Limpar"
            type="reset"
            icon="pi pi-times"
            onClick={e => clearAll()}
            className="p-button-danger"
            loading={isLoad}
          />
        </div>

        <div className="field col-12 md:col-6">
          <Button
            label={'Salvar'}
            icon="pi pi-check"
            className="p-button-success"
            type="submit"
            loading={isLoad}
          />
        </div>
      </Form>

      <Divider />

      <DataTable
        value={links}
        responsiveLayout="scroll"
        selectionMode="single"
        paginator
        rows={10}
        rowsPerPageOptions={[10, 20, 30]}
        size="small"
        emptyMessage="Nenhum item encontrado!"
        loading={isLoad}
        onSelectionChange={e => handleSelectLink(e.value)}
      >
        <Column field="id" header="#" body={renderColumnPosition} />
        <Column
          field="icon"
          header="Icone"
          body={row => imageBodyTemplate(row)}
        />
        <Column field="title" header="Título" />
        <Column
          header="Menu"
          align={'center'}
          body={row => renderButtons(row)}
        />
      </DataTable>
    </SideBar>
  );
};
export default SidebarSocial;
