import { endOfMonth, startOfMonth } from 'date-fns';

export type ISearchPurchase = {
  keyword: string | null;
  period: Date[];
  status: string[];
  status_id: number[];
};

export const emptySearchPurchase: ISearchPurchase = {
  keyword: null,
  period: [startOfMonth(new Date()), endOfMonth(new Date())],
  status: ['A', 'C'],
  status_id: [],
};
