import { useEffect, useRef, useState } from 'react';
import Progress from '../../../../components/progress-bar';
import PurchaseHeaderButtons from '../../../purchase/header-buttons';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { InputText } from '../../../../components/Inputs/InputText';
import CalendarRange from '../../../../components/Inputs/CalendarRange';
import { MultiSelect } from '../../../../components/Inputs/InputMultSelect';
import { Divider } from '../../../../components/Divider';
import { Button } from 'primereact/button';
import { emptySearchPurchase, ISearchPurchase } from './dtos';
import { Value } from 'sass';
import IOptionsDTO from '../../../business/dtos/IOptionsDTO';
import { emptyPagination, IPagination } from '../../../../types/pagination';
import api from '../../../../services/api';
import { AxiosError } from 'axios';
import messageRequestError from '../../../../utils/messageRequestError';
import { ToastContext } from '../../../../context/AppToastContext';
import useToastContext from '../../../../hooks/toast';
import openReports from '../../../../utils/openReports';
import { simpleDate } from '../../../../utils/date';

const ReportPurchaseList = () => {
  const formRef = useRef<FormHandles>(null);
  const toast = useToastContext();
  const [isLoad, setIsLoad] = useState(false);

  const [filter, setFilter] = useState<ISearchPurchase>(emptySearchPurchase);
  const [statusIds, setStatusIds] = useState<IOptionsDTO[]>([]);

  const clearAll = () => {
    setFilter(emptySearchPurchase);
  };

  const loadStatusId = async (pagination: IPagination) => {
    setIsLoad(true);
    await api
      .post('/purchase/status/list', { pagination })
      .then(({ data }) => {
        if (data) {
          setStatusIds(
            data.map((i: any) => {
              return {
                value: i.id,
                label: `${i.pos} - ${i.title}`,
              };
            }),
          );
        }
      })
      .catch((e: AxiosError) => {
        toast('warn', 'Alerta', messageRequestError(e));
      })
      .finally(() => {
        setIsLoad(false);
      });
  };

  const handleSubmit = async (d: any) => {
    setIsLoad(true);
    await api
      .post('/reports/purchase', {
        data: {
          ...filter,
        },
      })
      .then(({ data }) => {
        if (data) {
          openReports(
            `${process.env.REACT_APP_SERVER_URL}/files/pdf/${data}`,
            'ordens-de-compra',
          );
        }
      })
      .catch((e: AxiosError) => {})
      .finally(() => {
        setIsLoad(false);
      });
  };

  useEffect(() => {
    Promise.all([loadStatusId(emptyPagination)]);
  }, []);

  return (
    <>
      <PurchaseHeaderButtons />
      <Progress isLoad={isLoad} />
      <Form
        ref={formRef}
        onSubmit={d => handleSubmit(d)}
        className="p-fluid grid formgrid card"
        placeholder={''}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
      >
        <div className="field col-12">
          <h3>Relatório de ordens de compras</h3>
        </div>
        <div className="field col-12">
          <label htmlFor="keyword">Buscar</label>
          <InputText
            name="keyword"
            value={filter.keyword || ''}
            onChange={e =>
              setFilter({ ...filter, keyword: e.currentTarget.value })
            }
            placeholder="Ex.: Fornecedor ACME Ltda"
          />
        </div>
        <div className="field col-4">
          <label htmlFor="period">Período</label>
          <CalendarRange
            name="period"
            value={filter.period}
            onChange={e => setFilter({ ...filter, period: e.value as Date[] })}
            placeholder="00/00/00 ~ 00/00/00"
          />
        </div>
        <div className="field col-4">
          <label htmlFor="status">Status</label>
          <MultiSelect
            name="status"
            value={filter.status}
            options={[
              { value: 'A', label: 'Ativo' },
              { value: 'C', label: 'Cancelado' },
            ]}
            onChange={e => setFilter({ ...filter, status: e.value })}
            display="chip"
            placeholder="Selecione..."
          />
        </div>
        <div className="field col-4">
          <label htmlFor="status_id">Situação</label>
          <MultiSelect
            name="status"
            value={filter.status_id}
            options={statusIds}
            onChange={e => setFilter({ ...filter, status_id: e.value })}
            display="chip"
            placeholder="Selecione..."
          />
        </div>
        <Divider />
        <div className="field col-6">
          <Button
            label="Limpar"
            type="reset"
            icon="pi pi-times"
            onClick={e => clearAll()}
            className="p-button-danger"
            loading={isLoad}
          />
        </div>

        <div className="field col-6">
          <Button
            label="Salvar"
            icon="pi pi-check"
            className="p-button-success"
            type="submit"
            loading={isLoad}
            onClick={() => formRef.current?.submitForm()}
          />
        </div>
      </Form>
    </>
  );
};
export default ReportPurchaseList;
