import { classNames } from 'primereact/utils';
// eslint-disable-next-line object-curly-newline
import React, { useContext, useEffect, useRef, useState } from 'react';

import figlet from 'figlet';
import standard from 'figlet/importable-fonts/Standard.js';

import { Switch, useHistory, useLocation } from 'react-router-dom';

import PrimeReact from 'primereact/api';
import { Messages } from 'primereact/messages';
import { Tooltip } from 'primereact/tooltip';
import { AppSettingContext } from './context/AppSettingContext';

import AppBreadcrumb from './components/Layout/AppBreadcrumb';
import AppConfig from './components/Layout/AppConfig';
import AppFooter from './components/Layout/AppFooter';
import AppRightMenu from './components/Layout/AppRightMenu';
import AppSearch from './components/Layout/AppSearch';
import AppTopbar from './components/Layout/AppTopBar';

import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';
import './App.scss';

import api from './services/api';

import { AppContext } from './context/AppContext';
import routers from './routers/router';
import { RouterAppDynamic } from './routers/routerDynamic';

figlet.parseFont('Standard', standard);

interface SearchOption {
  name: string;
  code: string;
}

function App(): any {
  const { state } = useContext(AppSettingContext);
  const { dispatch } = useContext(AppContext);
  const [menuActive, setMenuActive] = useState(false);
  const [menuMode, setMenuMode] = useState(state.menuType || 'static');
  const [colorScheme, setColorScheme] = useState(state.colorScheme || 'light');
  const [menuTheme, setMenuTheme] = useState(
    state.menuThemes
      ? `layout-sidebar-${state.menuThemes}`
      : 'layout-sidebar-darkgray',
  );
  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] =
    useState(false);
  const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const [topbarUserMenuActive, setTopbarUserMenuActive] = useState(false);
  const [topbarNotificationMenuActive, setTopbarNotificationMenuActive] =
    useState(false);
  const [rightMenuActive, setRightMenuActive] = useState(false);
  const [configActive, setConfigActive] = useState<boolean>(false);
  const [inputStyle, setInputStyle] = useState(state.inputStyle || 'outlined');
  const [menuOption, setMenuOption] = useState<any>([]);
  const [ripple, setRipple] = useState(false);
  const [, setLogoColor] = useState(state.logoColor || 'white');
  const [componentTheme, setComponentTheme] = useState(
    state.componentThemes || 'blue',
  );
  const [searchOption, setSearchOption] = useState<Array<SearchOption>>([]);
  const [logoUrl, setLogoUrl] = useState('/assets/layout/images/logo-dark.svg');
  const copyTooltipRef = useRef<React.MutableRefObject<undefined>>();
  const messageDefault = useRef<any>(null);
  const message = JSON.parse(localStorage.getItem('@wg:sys_status') || '');

  const location = useLocation();
  const router = useHistory();

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      switch (event.key) {
        case 'F1':
          if (event.ctrlKey) return router.push('/cash-register/open');
          break;
        case 'F2':
          return router.push('/frente-caixa');
        case 'F3':
          return router.push('/orders/create');
        case 'F4':
          return router.push('/os/new');
        case 'F8':
          return router.push('/products/create');
        case 'F9':
          return router.push('/persons/create');
        case 'F10':
          return router.push('/products-prices/list');
        case 'b':
          if (event.ctrlKey) return router.push('/boleto/v1/titulo/list');
          break;
        case 'e':
          if (event.ctrlKey) return router.push('/input-products/create');
          break;
        case 'F11':
          if (event.ctrlKey) return router.push('/business/create');
          break;
        default:
          return;
      }
    };

    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [router]);

  let menuClick = false;
  let searchClick = false;
  let userMenuClick = false;
  let notificationMenuClick = false;
  let rightMenuClick = false;
  let configClick = false;

  const menuOptionsIcons: any = {
    consignado: 'fa-solid fa-clipboard-check',
    locações: 'fa-solid fa-right-left',
    compras: 'fa-solid fa-cubes',
    caixa: 'fa-solid fa-money-bill-transfer',
    contas_pagar: 'fa-solid fa-barcode',
    contas_receber: 'fa-solid fa-sack-dollar',
    estoque: 'fa-solid fa-boxes-stacked',
    marketing: 'fa-solid fa-bullhorn',
    afiliados: 'fa-solid fa-users',
    pdv: 'fa-solid fa-cash-register',
    vendas: 'fa-solid fa-file-invoice-dollar',
    pcp: 'fa-solid fa-industry',
    os: 'fa-solid fa-wrench',
    banco: 'fa-solid fa-piggy-bank',
    parametros: 'fa-solid fa-gear',
  };

  const systemForm = async () => {
    const acessUsers: Array<any> = [];
    const searchOption: Array<SearchOption> = [];
    const forms: Array<any> = [];
    const systemAcess = await api.get('/user-system-access/render/menu');

    Object.keys(systemAcess.data).forEach((typeAccess, index) => {
      const title: string = typeAccess
        .toLocaleLowerCase()
        .replace(/\s/, '_')
        .trim();
      acessUsers.push({
        icon: `${menuOptionsIcons[title]}`,
        expanded: false,
        items: [
          {
            label: typeAccess,
            ...(menuMode !== 'slim' &&
              menuMode !== 'horizontal' && {
                icon: `${menuOptionsIcons[title]}`,
              }),
            items: [],
          },
        ],
      });

      systemAcess.data[typeAccess] = systemAcess.data[typeAccess].sort(
        (a: any, b: any) => (a.form > b.form ? 1 : -1),
      );

      // Removendo paineis que sempre levam para páginas não encontradas,
      // como CRM Painel
      systemAcess.data[typeAccess] = systemAcess.data[typeAccess].filter(
        (f: any) => f.form_id !== '9b68b586-8856-11ee-bd02-13fcabf4c14c',
      );

      systemAcess.data[typeAccess].forEach((item: any) => {
        const searchRouter = searchOption.find(
          (search: any) => search.name === item.form,
        );
        if (!item.url.includes(':id') && !searchRouter) {
          searchOption.push({ name: item.form, code: item.url });
        }
        if (item.module === acessUsers[index].items[0].label) {
          forms.push({ module: item.form, pathname: item.url });
          const containForm = acessUsers[index].items[0].items.find(
            (contain: any) => contain.label === item.form,
          );

          if (!item.url.includes(':id') && !containForm) {
            acessUsers[index].items[0].items.push({
              label: item.form,
              to: item.url,
            });
          }
        }
      });
    });
    setMenuOption(acessUsers);
    dispatch({ type: 'SET_FORMS', payload: { forms: searchOption } });
    setSearchOption(searchOption);
  };

  const systemFormHorizontal = async () => {
    const acessUsers: Array<any> = [];
    const searchOption: Array<SearchOption> = [];
    const systemAcess = await api.get('/user-system-access/render/menu');

    Object.keys(systemAcess.data).forEach((typeAccess, index) => {
      const title: string = typeAccess
        .toLocaleLowerCase()
        .replace(/\s/, '_')
        .trim();
      acessUsers.push({
        icon: `${menuOptionsIcons[title]}`,
        expanded: false,
        items: [],
      });
      systemAcess.data[typeAccess].forEach((item: any) => {
        const searchRouter = searchOption.find(
          (search: any) => search.name === item.form,
        );
        if (!item.url.includes(':id') && !searchRouter) {
          searchOption.push({ name: item.form, code: item.url });
          acessUsers[index].items.push({ label: item.form, to: item.url });
        }
      });
    });
    setMenuOption(acessUsers);
    dispatch({ type: 'SET_FORMS', payload: { forms: searchOption } });
    setSearchOption(searchOption);
  };

  const renderMenu = () => {
    if (menuMode === 'horizontal' || menuMode === 'slim') {
      systemFormHorizontal();
    } else {
      systemForm();
    }
  };

  const menu = [...menuOption];

  useEffect(() => {
    renderMenu();
  }, [menuMode]);

  useEffect(() => {
    copyTooltipRef &&
      copyTooltipRef.current &&
      copyTooltipRef.current.updateTargetEvents();
  }, [location]);

  useEffect(() => {
    if (staticMenuMobileActive) {
      blockBodyScroll();
    } else {
      unblockBodyScroll();
    }
  }, [staticMenuMobileActive]);

  useEffect(() => {
    changeStyleSheetUrl('layout-css', `layout-${colorScheme}.css`, 1);
    changeStyleSheetUrl('theme-css', `theme-${colorScheme}.css`, 1);

    figlet.text(
      'webger',
      {
        font: 'Standard',
      },
      (err, data) => {
        if (err) {
          return;
        }
        console.log(
          `${data} \n \n Venha trabalhar conosco, veja as vagas em: \n https://webger.com.br/vagas/`,
        );
      },
    );

    if (!message) {
      messageDefault.current.show([
        {
          severity: 'warn',
          detail:
            'Consta um ou mais documentos em aberto no sistema. Procure nosso financeiro para reativar o sistema!',
          sticky: true,
          closable: false,
        },
      ]);
    }

    // const storedVersion = localStorage.getItem('appVersion');
    //

    // // Se a versão mudou, limpar cache e forçar reload
    // if (storedVersion !== process.env.REACT_APP_VERSION) {
    //   // localStorage.clear();
    //   localStorage.setItem('appVersion',
    // process.env.REACT_APP_VERSION || '');
    //   window.location.reload();
    // }
  }, []);

  const onInputStyleChange = (inputStyle: string) => {
    setInputStyle(inputStyle);
  };

  const changeMenuTheme = (
    name: string,
    logoColor: string,
    componentTheme: string,
  ) => {
    onMenuThemeChange(name);
    changeStyleSheetUrl('theme-css', componentTheme, 2);
    setComponentTheme(componentTheme);
    const appLogoLink = document.getElementById('app-logo') as HTMLImageElement;
    const appLogoUrl =
      menuMode !== 'static'
        ? `/assets/layout/images/logo-${
            logoColor === 'dark' ? 'dark' : 'white'
          }.svg`
        : '/webger.svg';
    const horizontalLogoLink = document.getElementById(
      'logo-horizontal',
    ) as HTMLImageElement;
    const horizontalLogoUrl =
      menuMode !== 'static'
        ? `/assets/layout/images/logo-${
            logoColor === 'dark' ? 'dark' : 'white'
          }.svg`
        : '/webger.svg';

    if (appLogoLink) {
      appLogoLink.src = appLogoUrl;
    }
    if (horizontalLogoLink) {
      horizontalLogoLink.src = horizontalLogoUrl;
    }
    setLogoColor(logoColor);
  };

  const changeComponentTheme = (theme: string) => {
    setComponentTheme(theme);
    changeStyleSheetUrl('theme-css', theme, 3);
  };

  const changeColorScheme = (e: any) => {
    setColorScheme(e.value);

    const scheme = e.value;
    changeStyleSheetUrl('layout-css', `layout-${scheme}.css`, 1);
    changeStyleSheetUrl('theme-css', `theme-${scheme}.css`, 1);

    changeLogo(scheme);
  };

  const changeStyleSheetUrl = (id: string, value: string, from: number) => {
    const element = document.getElementById(id) as HTMLAnchorElement;
    const urlTokens = element.getAttribute('href')?.split('/');

    if (from === 1 && urlTokens) {
      // which function invoked this function
      urlTokens[urlTokens.length - 1] = value;
    } else if (from === 2 && urlTokens) {
      // which function invoked this function
      if (value !== null) {
        urlTokens[urlTokens.length - 2] = value;
      }
    } else if (from === 3 && urlTokens) {
      // which function invoked this function
      urlTokens[urlTokens.length - 2] = value;
    }

    const newURL = urlTokens && urlTokens.join('/');

    newURL && replaceLink(element, newURL);
  };

  const changeLogo = (scheme: string) => {
    const appLogoLink = document.getElementById('app-logo') as HTMLImageElement;
    const mobileLogoLink = document.getElementById(
      'logo-mobile',
    ) as HTMLImageElement;
    const invoiceLogoLink = document.getElementById(
      'invoice-logo',
    ) as HTMLImageElement;
    const footerLogoLink = document.getElementById(
      'footer-logo',
    ) as HTMLImageElement;

    setLogoUrl(
      `/assets/layout/images/logo-${scheme === 'light' ? 'dark' : 'white'}.svg`,
    );

    if (appLogoLink) {
      // appLogoLink.src = `/assets/layout/images/logo-${
      //   scheme === 'light' ? 'dark' : 'white'
      // }.svg`;

      // if (horizontalLogoLink) {
      //   horizontalLogoLink.src = `/assets/layout/images/logo-${
      //     scheme === 'light' ? logoColor : 'white'
      //   }.svg`
      // }

      if (mobileLogoLink) {
        mobileLogoLink.src = logoUrl;
      }

      if (invoiceLogoLink) {
        invoiceLogoLink.src = logoUrl;
      }

      if (footerLogoLink) {
        footerLogoLink.src =
          scheme === 'light' ? '/webger-black.svg' : '/webger.svg';
      }
    }
  };

  const replaceLink = (linkElement: HTMLAnchorElement, href: string) => {
    if (isIE() && linkElement) {
      linkElement.setAttribute('href', href);
    } else {
      const id = linkElement.getAttribute('id');
      const cloneLinkElement = linkElement.cloneNode(true) as HTMLAnchorElement;

      cloneLinkElement.setAttribute('href', href);
      cloneLinkElement.setAttribute('id', `${id}-clone`);

      linkElement?.parentNode?.insertBefore(
        cloneLinkElement,
        linkElement.nextSibling,
      );

      id &&
        cloneLinkElement.addEventListener('load', () => {
          linkElement.remove();
          cloneLinkElement.setAttribute('id', id);
        });
    }
  };

  const isIE = () =>
    /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onRippleChange = (e: any) => {
    PrimeReact.ripple = e.value;
    setRipple(e.value);
  };

  const onDocumentClick = () => {
    if (!searchClick && searchActive) {
      onSearchHide();
    }

    if (!userMenuClick) {
      setTopbarUserMenuActive(false);
    }

    if (!notificationMenuClick) {
      setTopbarNotificationMenuActive(false);
    }

    if (!rightMenuClick) {
      setRightMenuActive(false);
    }

    if (!menuClick) {
      if (isSlim() || isHorizontal()) {
        setMenuActive(false);
      }

      if (overlayMenuActive || staticMenuMobileActive) {
        hideOverlayMenu();
      }

      unblockBodyScroll();
    }

    if (configActive && !configClick) {
      setConfigActive(false);
    }

    searchClick = false;
    configClick = false;
    userMenuClick = false;
    rightMenuClick = false;
    notificationMenuClick = false;
    menuClick = false;
  };

  const onMenuClick = () => {
    menuClick = true;
  };

  const onMenuButtonClick = (event: any) => {
    menuClick = true;
    setTopbarUserMenuActive(false);
    setTopbarNotificationMenuActive(false);
    setRightMenuActive(false);

    if (isOverlay()) {
      setOverlayMenuActive(prevOverlayMenuActive => !prevOverlayMenuActive);
    }

    if (isDesktop()) {
      setStaticMenuDesktopInactive(
        prevStaticMenuDesktopInactive => !prevStaticMenuDesktopInactive,
      );
    } else {
      setStaticMenuMobileActive(
        prevStaticMenuMobileActive => !prevStaticMenuMobileActive,
      );
    }

    event.preventDefault();
  };

  const onMenuitemClick = (event: any) => {
    if (!event.item.items) {
      hideOverlayMenu();

      if (isSlim() || isHorizontal()) {
        setMenuActive(false);
      }
    }
  };

  const onRootMenuitemClick = () => {
    setMenuActive(prevMenuActive => !prevMenuActive);
  };

  const onMenuThemeChange = (name: string) => {
    setMenuTheme(`layout-sidebar-${name}`);
  };

  const onMenuModeChange = (e: any) => {
    setMenuMode(e.value);
    if (e.value === 'static') {
      setStaticMenuDesktopInactive(false);
    }
  };

  const onTopbarUserMenuButtonClick = (event: any) => {
    userMenuClick = true;
    setTopbarUserMenuActive(
      prevTopbarUserMenuActive => !prevTopbarUserMenuActive,
    );

    hideOverlayMenu();

    event.preventDefault();
  };

  const onTopbarNotificationMenuButtonClick = (event: any) => {
    notificationMenuClick = true;
    setTopbarNotificationMenuActive(
      prevTopbarNotificationMenuActive => !prevTopbarNotificationMenuActive,
    );

    hideOverlayMenu();

    event.preventDefault();
  };

  const toggleSearch = () => {
    setSearchActive(prevSearchActive => !prevSearchActive);
    searchClick = true;
  };

  const onSearchClick = () => {
    searchClick = true;
  };

  const onSearchHide = () => {
    setSearchActive(false);
    searchClick = false;
  };

  const onRightMenuClick = () => {
    rightMenuClick = true;
  };

  const onRightMenuButtonClick = (event: any) => {
    rightMenuClick = true;
    setRightMenuActive(prevRightMenuActive => !prevRightMenuActive);
    hideOverlayMenu();
    event.preventDefault();
  };

  const onConfigClick = () => {
    configClick = true;
  };

  const onConfigButtonClick = () => {
    setConfigActive(prevConfigActive => !prevConfigActive);
    configClick = true;
  };

  const hideOverlayMenu = () => {
    setOverlayMenuActive(false);
    setStaticMenuMobileActive(false);
    unblockBodyScroll();
  };

  const blockBodyScroll = () => {
    if (document.body.classList) {
      document.body.classList.add('blocked-scroll');
    } else {
      document.body.className += ' blocked-scroll';
    }
  };

  const unblockBodyScroll = () => {
    if (document.body.classList) {
      document.body.classList.remove('blocked-scroll');
    } else {
      document.body.className = document.body.className.replace(
        new RegExp(
          `(^|\\b)${'blocked-scroll'.split(' ').join('|')}(\\b|$)`,
          'gi',
        ),
        ' ',
      );
    }
  };

  const isSlim = () => menuMode === 'slim';

  const isHorizontal = () => menuMode === 'horizontal';

  const isOverlay = () => menuMode === 'overlay';

  const isDesktop = () => window.innerWidth > 1091;

  const containerClassName = classNames(
    'layout-wrapper',
    {
      'layout-overlay': menuMode === 'overlay',
      'layout-static': menuMode === 'static',
      'layout-slim': menuMode === 'slim',
      'layout-horizontal': menuMode === 'horizontal',
      'layout-sidebar-dim': colorScheme === 'dim',
      'layout-sidebar-dark': colorScheme === 'dark',
      'layout-overlay-active': overlayMenuActive,
      'layout-mobile-active': staticMenuMobileActive,
      'layout-static-inactive':
        staticMenuDesktopInactive && menuMode === 'static',
      'p-input-filled': inputStyle === 'filled',
      'p-ripple-disabled': !ripple,
    },
    colorScheme === 'light' ? menuTheme : '',
  );

  return (
    <div
      className={containerClassName}
      data-theme={colorScheme}
      onClick={onDocumentClick}
    >
      <Tooltip
        ref={copyTooltipRef}
        target=".block-action-copy"
        position="bottom"
        content="Copied to clipboard"
        event="focus"
      />
      <div className="layout-content-wrapper">
        <AppTopbar
          routers={routers}
          topbarNotificationMenuActive={topbarNotificationMenuActive}
          topbarUserMenuActive={topbarUserMenuActive}
          onMenuButtonClick={onMenuButtonClick}
          onSearchClick={toggleSearch}
          onTopbarNotification={onTopbarNotificationMenuButtonClick}
          onTopbarUserMenu={onTopbarUserMenuButtonClick}
          onRightMenuClick={onRightMenuButtonClick}
          onRightMenuButtonClick={onRightMenuButtonClick}
          menu={menu}
          menuMode={menuMode}
          menuActive={menuActive}
          staticMenuMobileActive={staticMenuMobileActive}
          onMenuClick={onMenuClick}
          onMenuitemClick={onMenuitemClick}
          onRootMenuitemClick={onRootMenuitemClick}
        />

        <div className="layout-content">
          <div
            className="layout-breadcrumb viewname"
            style={{ textTransform: 'uppercase' }}
          >
            <AppBreadcrumb routers={routers} />
          </div>
          <Messages ref={messageDefault} />

          <Switch>
            {routers.map((router, index) => {
              if (router.exact) {
                return (
                  <RouterAppDynamic
                    key={`router${index}`}
                    pageId={router.pageId}
                    path={router.path}
                    exact
                    component={router.component}
                    render={router.render}
                  />
                );
              }

              return (
                <RouterAppDynamic
                  key={`router${index}`}
                  pageId={router.pageId}
                  path={router.path}
                  component={router.component}
                  render={router.render}
                />
              );
            })}
          </Switch>
        </div>

        <AppFooter />
      </div>
      <AppRightMenu
        rightMenuActive={rightMenuActive}
        onRightMenuClick={onRightMenuClick}
      />
      <AppConfig
        configActive={configActive}
        menuMode={menuMode}
        onMenuModeChange={onMenuModeChange}
        colorScheme={colorScheme}
        changeColorScheme={changeColorScheme}
        menuTheme={menuTheme}
        changeMenuTheme={changeMenuTheme}
        componentTheme={componentTheme}
        changeComponentTheme={changeComponentTheme}
        onConfigClick={onConfigClick}
        onConfigButtonClick={onConfigButtonClick}
        rippleActive={ripple}
        onRippleChange={onRippleChange}
        inputStyle={inputStyle}
        onInputStyleChange={onInputStyleChange}
      />
      <AppSearch
        searchActive={searchActive}
        onSearchClick={onSearchClick}
        onSearchHide={onSearchHide}
        searchOption={searchOption}
      />
    </div>
  );
}

export default App;
