import React, { useRef, useEffect } from 'react';
import { useField } from '@unform/core';
import { Dropdown, DropdownProps } from 'primereact/dropdown';
import IOptionsDTO from '../../../pages/business/dtos/IOptionsDTO';
import { icons } from './icons';

interface PropsInput extends DropdownProps {
  name: string;
}

type IconOption = {
  label: JSX.Element;
  value: string;
};

export const InputIcons: React.FC<PropsInput> = ({ name, ...rest }) => {
  const inputRef = useRef(null);

  const { fieldName, registerField, error } = useField(name);

  const iconOptions: IconOption[] = icons.map(icon => ({
    label: (
      <>
        {icon.label}
        <i className={`${icon.value} text-gray-500 ml-3`}></i>
      </>
    ),
    value: icon.value,
  }));

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Dropdown
      filter
      inputRef={inputRef}
      options={icons}
      {...rest}
      emptyMessage="Nenhuma opção foi encontrada"
      emptyFilterMessage="Nenhuma opção foi encontrada"
      className={error ? `${rest.className} p-invalid` : `${rest.className}`}
    />
  );
};
