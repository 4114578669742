import React, { useEffect, useRef, useState } from 'react';
import PurchaseHeaderButtons from '../header-buttons';
import Progress from '../../../components/progress-bar';
import { Form } from '@unform/web';
import { usePurchase } from '../../../hooks/usePurchase';
import { FormHandles } from '@unform/core';
import { validate } from 'uuid';
import { InputAutoComplete } from '../../../components/Inputs/InputAutoComplete';
import useToastContext from '../../../hooks/toast';
import IOptionsDTO, { emptyOption } from '../../business/dtos/IOptionsDTO';
import { InputDropDown } from '../../../components/Inputs/InputDropDown';
import SidebarPurchaseStatus from '../sidebar-purchase-status';
import { FastFormPerson } from '../../person/fast-form-person';
import { EnumBehavior } from '../../../enum/EnumBehavior';
import Calendar from '../../../components/Inputs/InputCalendar';
import { InputNumber } from '../../../components/Inputs/InputNumber';
import { Button } from 'primereact/button';
import { Divider } from '../../../components/Divider';
import InputRowPurchase from '../../../components/input-row/purchase';
import messageRequestError from '../../../utils/messageRequestError';
import useSearchProvider from '../../../hooks/useSearchProvider';
import { IProviderSearchItemDTO } from '../../bills-to-pay/create/dtos/IProviderSearchItemDTO';
import * as Yup from 'yup';
import { IPagination } from '../../../types/pagination';
import api from '../../../services/api';
import { PurchaseStatus } from '../../../types/purchase';
import getValidationErrors from '../../../utils/getErrorsValidation';

const PurchaseCreate = () => {
  const formRef = useRef<FormHandles>(null);
  const toast = useToastContext();

  const [isLoad, setIsLoad] = useState(false);

  const [sidebarProviderVisible, setSidebarProviderVisible] = useState(false);
  const [sidebarStatusVisible, setSidebarStatusVisible] = useState(false);
  const [provider, setProvider] = useState<IOptionsDTO>(emptyOption);
  const [providers, setProviders] = useState<IOptionsDTO[]>([]);
  const [statusIds, setStatusIds] = useState<IOptionsDTO[]>([]);

  const { purchase, setData, clearAll } = usePurchase();

  const searchProvider = async (search: string) => {
    setIsLoad(true);
    try {
      const res = await useSearchProvider({ search });
      if (res) {
        setProviders(
          res.map((item: IProviderSearchItemDTO) => {
            return {
              value: item.id,
              label: item.name,
            };
          }),
        );
      }
    } catch (e: any) {
      toast('warn', 'Alerta', messageRequestError(e));
    } finally {
      setIsLoad(false);
    }
  };

  const loadPurchaseStatus = async (pagination: IPagination) => {
    setIsLoad(true);
    try {
      await api
        .post('/purchase/status/list', { pagination })
        .then(({ data }) => {
          if (data) {
            setStatusIds(
              data.map((i: PurchaseStatus) => {
                return {
                  value: i.id,
                  label: i.title,
                };
              }),
            );
          }
        })
        .catch(e => {
          toast('warn', 'Alerta', messageRequestError(e));
        })
        .finally(() => {
          setIsLoad(false);
        });
    } catch (e: any) {
      toast('warn', 'Alerta', messageRequestError(e));
    } finally {
      setIsLoad(false);
    }
  };

  const itemTemplate = (item: IOptionsDTO) => {
    return (
      <div className="country-item">
        <div>{item.label}</div>
      </div>
    );
  };

  const handleSubmit = async (d: any) => {
    try {
      setIsLoad(true);
      formRef.current?.setErrors({});
      const schema = Yup.object({});
      await schema.validate(purchase, { abortEarly: false });
      await api
        .post('/purchase', {
          data: {
            purchase: {
              provider_id: purchase.provider_id,
              total_on: purchase.total_on,
              total_off: purchase.total_off,
              total: purchase.total,
              days_sugest: purchase.days_sugest,
              status_id: purchase.status_id,
            },
            items: purchase.purchaseItems.map(i => {
              return {
                product_id: i.product_id,
                unity: i.unity,
                qnt: i.qnt,
                vlr: i.vlr,
                add: i.add,
                desc: i.desc,
                total: i.total,
                able: i.able,
              };
            }),
          },
        })
        .then(({ data }) => {
          if (data) {
            toast('success', 'Sucesso', 'Registro criado com sucesso');
            handleClearAll();
          }
        })
        .catch((e: any) => {
          toast('warn', 'Alerta', messageRequestError(e));
        })
        .finally(() => {
          setIsLoad(false);
        });
    } catch (err: any) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        toast('error', 'Error', err.errors[0]);
      } else {
        toast('error', 'Error', err.response?.data?.error);
      }
    } finally {
      setIsLoad(false);
    }
  };

  const handleClearAll = () => {
    clearAll();
    setProvider(emptyOption);
  };

  useEffect(() => {
    Promise.all([loadPurchaseStatus({ page: 1, perPage: 100, status: ['A'] })]);
  }, [, sidebarStatusVisible]);

  return (
    <>
      <PurchaseHeaderButtons />
      <Progress isLoad={isLoad} />
      <div className="card">
        <Form
          ref={formRef}
          onSubmit={d => handleSubmit(d)}
          className="p-fluid grid formgrid"
          placeholder={''}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        >
          <div className="field col-6">
            <label htmlFor="provider_id">
              Fornecedor{' '}
              <i
                className={`pi ${
                  validate(purchase.provider_id)
                    ? 'pi-search text-blue-500'
                    : 'pi-plus-circle text-green-500'
                } ml-2 cursor-pointer icon-sidebar-person`}
                onClick={() => setSidebarProviderVisible(true)}
              ></i>
            </label>
            <InputAutoComplete
              name="provider_id"
              suggestions={providers}
              value={provider}
              completeMethod={e => searchProvider(e.query)}
              field="label"
              onChange={e => {
                setProvider(e.value);
                setData({ ...purchase, provider_id: e.value.value });
              }}
              itemTemplate={itemTemplate}
              placeholder="Buscar fornecedor..."
            />
          </div>

          <div className="field col-12 md:col-3">
            <label htmlFor="nat_ope_id">
              Status
              <i
                className={`pi ${
                  validate(purchase.provider_id)
                    ? 'pi-search text-blue-500'
                    : 'pi-plus-circle text-green-500'
                } ml-2 cursor-pointer icon-sidebar-person`}
                onClick={() => setSidebarStatusVisible(true)}
              ></i>
            </label>
            <InputDropDown
              name="status_id"
              options={statusIds}
              value={purchase.status_id}
              onChange={e =>
                setData({
                  ...purchase,
                  status_id: e.value,
                })
              }
              placeholder="Selecionar..."
              filter
            />
          </div>

          <div className="field col-2">
            <label htmlFor="created_at">Abertura</label>
            <Calendar
              name="created_at"
              value={purchase.created_at}
              dateFormat="d/m/yy"
              disabled
              className="surface-200"
              placeholder="00/00/00"
            />
          </div>

          <div className="field col-1">
            <label htmlFor="codigo">Código</label>
            <InputNumber
              name="codigo"
              value={purchase.codigo}
              disabled
              className="surface-200"
              placeholder="000"
            />
          </div>

          <div className="field col-2">
            <label htmlFor="delivery_date">Previsão entrega</label>
            <Calendar
              name="delivery_date"
              value={purchase.delivery_date || undefined}
              dateFormat="d/m/yy"
              showIcon
              placeholder="00/00/00"
            />
          </div>

          <div className="field col-2">
            <label htmlFor="days_sugest">Sug. estoque (dias)</label>
            <InputNumber
              name="days_sugest"
              value={purchase.days_sugest}
              mode="decimal"
              minFractionDigits={0}
              maxFractionDigits={0}
              placeholder="0,00"
            />
          </div>

          <div className="field col-2 flex align-items-end justify-content-center">
            <Button
              type="button"
              loading={isLoad}
              disabled={purchase.purchaseItems.length === 0}
              className="p-button-info"
              icon="fa-solid fa-refresh"
              label="Gerar"
            />
          </div>

          <div className="field col-2">
            <label htmlFor="total_on">Total disponível</label>
            <InputNumber
              name="total_on"
              value={purchase.total_on}
              mode="decimal"
              minFractionDigits={2}
              maxFractionDigits={2}
              placeholder="0,00"
              disabled
              className="surface-200"
            />
          </div>

          <div className="field col-2">
            <label htmlFor="total_off">Total indisponível</label>
            <InputNumber
              name="total_off"
              value={purchase.total_off}
              mode="decimal"
              minFractionDigits={2}
              maxFractionDigits={2}
              placeholder="0,00"
              disabled
              className="surface-200"
            />
          </div>

          <div className="field col-2">
            <label htmlFor="total">Total</label>
            <InputNumber
              name="total"
              value={purchase.total}
              mode="decimal"
              minFractionDigits={2}
              maxFractionDigits={2}
              placeholder="0,00"
              disabled
              className="surface-200"
            />
          </div>
        </Form>

        <Divider />

        <InputRowPurchase />

        <div className="p-fluid grid formgroup pb-0 mb-0">
          <div className="field col-6">
            <Button
              label="Limpar"
              type="reset"
              icon="pi pi-times"
              onClick={e => clearAll()}
              className="p-button-danger"
              loading={isLoad}
            />
          </div>

          <div className="field col-6">
            <Button
              label="Salvar"
              icon="pi pi-check"
              className="p-button-success"
              type="submit"
              loading={isLoad}
              onClick={() => formRef.current?.submitForm()}
            />
          </div>
        </div>
      </div>

      <FastFormPerson
        isOpen={sidebarProviderVisible}
        onRequestClose={() => setSidebarProviderVisible(false)}
        personType="P"
        behavior={EnumBehavior.CREATE}
        personId={''}
      />

      <SidebarPurchaseStatus
        isOpen={sidebarStatusVisible}
        onRequestClose={() => setSidebarStatusVisible(false)}
      />
    </>
  );
};

export default PurchaseCreate;
