import { createContext, ReactNode, useEffect, useState } from 'react';
import {
  emptyPurchase,
  emptyPurchaseItem,
  Purchase,
  PurchaseItem,
} from '../types/purchase';
import api from '../services/api';
import IOptionsDTO, { emptyOption } from '../pages/business/dtos/IOptionsDTO';

interface IContextData {
  purchase: Purchase;
  setProvider: (provider: IOptionsDTO) => void;
  setData: (purchase: Purchase) => void;
  clearData: () => void;
  fetchData: (id: string) => void;
  initializedData: () => void;

  item: PurchaseItem;
  setItem: (item: PurchaseItem) => void;
  clearItem: () => void;
  addItem: (item: PurchaseItem) => void;
  remItem: (id: number) => void;

  clearAll: () => void;

  loadContext: boolean;
}

export const PurchaseContext = createContext<IContextData | undefined>(
  undefined,
);

export function PurchaseProvider({ children }: { children: ReactNode }) {
  const [loadContext, setLoadContext] = useState<boolean>(false);
  const [isInitializated, setIsInitializated] = useState<boolean>(false);

  const [purchase, setPurchase] = useState<Purchase>(emptyPurchase);
  const [provider, setProvider] = useState<IOptionsDTO>(emptyOption);
  const [item, setItem] = useState<PurchaseItem>(emptyPurchaseItem);

  const clearAll = () => {
    setIsInitializated(false);
    clearData();
  };

  const initializedData = async () => {
    if (!isInitializated) {
      setLoadContext(true);

      await Promise.all([]).finally(() => setLoadContext(false));

      if (!isInitializated) setIsInitializated(true);
    }
  };

  const setData = (item: Purchase) => {
    setPurchase(item);
  };

  const clearData = () => {
    setPurchase(emptyPurchase);
  };

  const fetchData = async (id: string) => {
    api.get(`/purchase/${id}`).then(({ data }) => {
      if (data) {
        setData(data);
        setProvider({ value: data.provider.id, label: data.provider.name });
      }
    });
  };

  const addItem = (item: PurchaseItem) => {
    item.id === purchase.purchaseItems.length + 1;
    setPurchase({
      ...purchase,
      purchaseItems: [
        ...purchase.purchaseItems.filter(i => i.id !== item.id),
        item,
      ],
    });
    clearItem();
  };

  const remItem = (id: number) => {
    setPurchase({
      ...purchase,
      purchaseItems: [...purchase.purchaseItems.filter(i => i.id !== id)],
    });
  };

  const clearItem = () => {
    setItem(emptyPurchaseItem);
  };

  useEffect(() => {
    setPurchase({
      ...purchase,
      total_on: purchase.purchaseItems.reduce((a, b) => {
        a += b.able ? Number(b.total) : 0;
        return a;
      }, 0),
      total_off: purchase.purchaseItems.reduce((a, b) => {
        a += !b.able ? Number(b.total) : 0;
        return a;
      }, 0),
      total: purchase.purchaseItems.reduce((a, b) => {
        a += Number(b.total);
        return a;
      }, 0),
    });
  }, [purchase.purchaseItems]);

  return (
    <PurchaseContext.Provider
      value={{
        purchase,
        setProvider,
        setData,
        clearData,
        fetchData,
        initializedData,
        item,
        setItem,
        clearItem,
        addItem,
        remItem,
        clearAll,
        loadContext,
      }}
    >
      {children}
    </PurchaseContext.Provider>
  );
}
