import api from '../services/api';
import messageRequestError from '../utils/messageRequestError';

interface IRequestDTO {
  search: string;
  status?: string[];
}

export default async function useSearchProvider({
  search,
  status,
}: IRequestDTO): Promise<any[] | undefined> {
  let providers = undefined;

  const url = '/persons/provider/src';

  if (search.length >= 3) {
    await api
      .post(url, {
        data: {
          search,
          status: status ? status : ['A'],
        },
      })
      .then(({ data }) => {
        if (data) {
          if (data.length === 0) {
            throw new Error('Nenhum fornecedor encontrado!');
          }

          providers = data;
        }
      })
      .catch((err: any) => {
        throw new Error(err.toString().substring(7));
      });
  }

  return providers;
}
