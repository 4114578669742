import { Form } from '@unform/web';
import * as Yup from 'yup';
import { SideBar } from '../../../../components/Sidebar';
import { useEffect, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { InputText } from '../../../../components/Inputs/InputText';
import { InputNumber } from '../../../../components/Inputs/InputNumber';
import { InputColor } from '../../../../components/Inputs/InputColor';
import { FileUpload, FileUploadHandlerParam } from 'primereact/fileupload';
import { uploadImageEmptyTemplate } from '../../../../components/upload/templates/upload-image-empty-template';
import { emptyLinknabioLink, LinknabioLink } from '../dtos';
import { Divider } from '../../../../components/Divider';
import { Button } from 'primereact/button';
import { DataTable } from '../../../../components/DataTable';
import { Column } from 'primereact/column';
import renderColumnPosition from '../../../../components/DataTableColumns/RenderColumnPosition';
import api from '../../../../services/api';
import { AxiosError } from 'axios';
import messageRequestError from '../../../../utils/messageRequestError';
import useToastContext from '../../../../hooks/toast';
import { emptyPagination, IPagination } from '../../../../types/pagination';
import getValidationErrors from '../../../../utils/getErrorsValidation';
import { v4 } from 'uuid';

type Iprops = {
  linkId?: string;
  isOpen: boolean;
  onRequestClose: () => void;
};

const SidebarLink = ({ linkId, isOpen, onRequestClose }: Iprops) => {
  const formRef = useRef<FormHandles>(null);
  const uploadRef = useRef<any>(null);
  const toast = useToastContext();

  const [isLoad, setIsLoad] = useState(false);
  const [link, setLink] = useState<Partial<LinknabioLink>>(emptyLinknabioLink);
  const [links, setLinks] = useState<LinknabioLink[]>([]);

  const handleUploadImage = async (e: FileUploadHandlerParam) => {};

  const handleSelectLink = (item: LinknabioLink) => {
    if (item) {
      setLink(item);
    }
  };

  const handleDeleteLink = async (id: string) => {
    setIsLoad(true);
    await api
      .delete(`/mkt/linknabio/link/${id}`)
      .then(({ data }) => {
        if (data) {
          toast('success', 'Sucesso', 'Item removido com sucesso.');
          loadLinks(emptyPagination);
        }
      })
      .catch((e: AxiosError) => {
        toast('warn', 'Alerta', messageRequestError(e));
      })
      .finally(() => {
        setIsLoad(false);
      });
  };

  const loadLinks = async (pagination: IPagination) => {
    setIsLoad(true);
    await api
      .post('/mkt/linknabio/link/all', { pagination })
      .then(({ data }) => {
        if (data) {
          setLinks([...data]);
        }
      })
      .catch((e: AxiosError) => {
        toast('warn', 'Alerta', messageRequestError(e));
      })
      .finally(() => {
        setIsLoad(false);
      });
  };

  const handleSubmit = async (d: any) => {
    try {
      setIsLoad(true);
      formRef.current?.setErrors({});
      const schema = Yup.object({
        pos: Yup.number()
          .required()
          .min(1, 'Posição deve ser igual ou maior que 1'),
        title: Yup.string().required('Informe um título para o link'),
        url: Yup.string()
          .url('Informe no formato url com http:// ou https://')
          .required('Informe um link'),
      });
      await schema.validate(link, { abortEarly: false });

      link.id = v4();
      link.link_id = linkId;

      await api
        .post('/mkt/linknabio/link', {
          data: {
            id: link.id,
            pos: link.pos,
            link_id: link.link_id,
            title: link.title,
            image_url: link.image_url,
            url: link.url,
            btn_background: link.btn_background,
            btn_border: link.btn_border,
            btn_color: link.btn_color,
          },
        })
        .then(({ data }) => {
          if (data) {
            toast('success', 'Sucesso', 'Registro criado com sucesso');
            loadLinks(emptyPagination);
            clearAll();
          }
        })
        .catch((e: any) => {
          toast('warn', 'Alerta', messageRequestError(e));
        })
        .finally(() => {
          setIsLoad(false);
        });
    } catch (err: any) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        toast('error', 'Error', err.errors[0]);
      } else {
        toast('error', 'Error', err.response?.data?.error);
      }
    } finally {
      setIsLoad(false);
    }
  };

  const clearAll = async () => {
    setLink(emptyLinknabioLink);
  };

  const imageBodyTemplate = (link: LinknabioLink) => {
    return (
      <img
        key={link.id}
        src={`${process.env.REACT_APP_SERVER_URL}/files/img/${link.image_url}`}
        alt={link.title}
        className="w-2rem shadow-2 border-round"
      />
    );
  };

  const renderButtons = (link: LinknabioLink) => {
    return (
      <>
        <Button
          key={link.id}
          icon="fa-solid fa-trash"
          className="p-button-danger"
          type="button"
          loading={isLoad}
          onClick={() => handleDeleteLink(link.id)}
        />
      </>
    );
  };

  useEffect(() => {
    loadLinks(emptyPagination);
  }, []);

  return (
    <SideBar
      visible={isOpen}
      onHide={() => {
        onRequestClose();
      }}
      position="right"
      style={{ width: '60vw' }}
    >
      <Form
        ref={formRef}
        onSubmit={d => handleSubmit(d)}
        className="p-fluid grid formgrid"
        placeholder={''}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
      >
        <div className="field col-12">
          <h1>Links</h1>
        </div>

        <div className="field col-2">
          <label htmlFor="pos">Posição</label>
          <InputNumber
            name="pos"
            placeholder="Ex.: 000"
            value={link.pos}
            onChange={e => setLink({ ...link, pos: e.value as number })}
          />
        </div>
        <div className="field col-10">
          <label htmlFor="title">Título</label>
          <InputText
            name="title"
            placeholder="Ex.: Whatsapp Vendedora 1"
            value={link.title}
            onChange={e => setLink({ ...link, title: e.currentTarget.value })}
          />
        </div>
        <div className="field col-12">
          <label htmlFor="url">Link</label>
          <InputText
            name="url"
            type="url"
            placeholder="Ex.: www.minhaurl.com.br"
            value={link.url}
            onChange={e => setLink({ ...link, url: e.currentTarget.value })}
          />
        </div>
        <div className="field col-12">
          <label htmlFor="image_url">Imagem</label>
          <FileUpload
            ref={uploadRef}
            name="img_url"
            mode="advanced"
            url={'/business/logo/upload'}
            multiple={false}
            accept="image/*"
            maxFileSize={1000000}
            emptyTemplate={() => uploadImageEmptyTemplate(link.image_url || '')}
            className="field col-12 mx-0 px-0"
            chooseLabel="Escolher"
            uploadLabel="Enviar"
            cancelLabel="Cancelar"
            customUpload
            uploadHandler={e => handleUploadImage(e)}
          />
        </div>
        <div className="field col-4">
          <label htmlFor="btn_background">Cor de fundo do botão</label>
          <InputColor
            name="btn_background"
            value={link.btn_background}
            onChange={e =>
              setLink({ ...link, btn_background: e.value as string })
            }
          />
          <span>{link.btn_background && `#${link.btn_background}`}</span>
        </div>
        <div className="field col-4">
          <label htmlFor="btn_border">Cor da borda do botão</label>
          <InputColor
            name="btn_border"
            value={link.btn_border}
            onChange={e => setLink({ ...link, btn_border: e.value as string })}
          />
          <span>{link.btn_border && `#${link.btn_border}`}</span>
        </div>
        <div className="field col-4">
          <label htmlFor="btn_color">Cor do botão</label>
          <InputColor
            name="btn_color"
            value={link.btn_color}
            onChange={e => setLink({ ...link, btn_color: e.value as string })}
          />
          <span>{link.btn_color && `#${link.btn_color}`}</span>
        </div>

        <Divider />

        <div className="field col-12 md:col-6">
          <Button
            label="Limpar"
            type="reset"
            icon="pi pi-times"
            onClick={e => clearAll()}
            className="p-button-danger"
            loading={isLoad}
          />
        </div>

        <div className="field col-12 md:col-6">
          <Button
            label={'Salvar'}
            icon="pi pi-check"
            className="p-button-success"
            type="submit"
            loading={isLoad}
          />
        </div>
      </Form>

      <Divider />

      <DataTable
        value={links}
        responsiveLayout="scroll"
        selectionMode="single"
        paginator
        rows={10}
        rowsPerPageOptions={[10, 20, 30]}
        size="small"
        emptyMessage="Nenhum item encontrado!"
        loading={isLoad}
        onSelectionChange={e => handleSelectLink(e.value)}
      >
        <Column field="id" header="#" body={renderColumnPosition} />
        <Column field="pos" header="Posição" align={'center'} />
        <Column
          field="image_url"
          header="Img"
          body={row => imageBodyTemplate(row)}
        />
        <Column field="title" header="Título" />
        <Column
          header="Menu"
          align={'center'}
          body={row => renderButtons(row)}
        />
      </DataTable>
    </SideBar>
  );
};
export default SidebarLink;
