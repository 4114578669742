export type IPagination = {
  page?: number | 1;
  perPage?: number | 100;
  status?: string[] | ['A'];
};

export const emptyPagination: IPagination = {
  page: 1,
  perPage: 100,
  status: ['A'],
};
