import React, { useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { SideBar } from '../../../components/Sidebar';
import { emptyPagination, IPagination } from '../../../types/pagination';
import { emptyPurchaseStatus, PurchaseStatus } from '../../../types/purchase';
import { FormHandles } from '@unform/core';
import { InputNumber } from '../../../components/Inputs/InputNumber';
import { InputText } from '../../../components/Inputs/InputText';
import { Button } from 'primereact/button';
import { Divider } from '../../../components/Divider';
import { DataTable } from '../../../components/DataTable';
import { DataTableSelectParams } from 'primereact/datatable';
import { Column } from 'primereact/column';
import renderColumnPosition from '../../../components/DataTableColumns/RenderColumnPosition';
import renderColumnStatus from '../../../components/DataTableColumns/RenderColumnStatus';
import api from '../../../services/api';
import { AxiosError } from 'axios';
import useToastContext from '../../../hooks/toast';
import messageRequestError from '../../../utils/messageRequestError';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import getValidationErrors from '../../../utils/getErrorsValidation';
import { EnumStatus } from '../../../enum/EnumStatus';

type IProps = {
  isOpen: boolean;
  onRequestClose: () => void;
};

const SidebarPurchaseStatus = ({
  isOpen,
  onRequestClose,
}: IProps): React.ReactElement => {
  const formRef = useRef<FormHandles>(null);
  const toast = useToastContext();

  const [isLoad, setIsLoad] = useState(false);
  const [item, setItem] = useState<PurchaseStatus>(emptyPurchaseStatus);
  const [items, setItems] = useState<PurchaseStatus[]>([]);

  const loadPurchaseStatus = async (pagination: IPagination) => {
    setIsLoad(true);
    await api
      .post('/purchase/status/list', { pagination })
      .then(({ data }) => {
        if (data) {
          setItems(data);
        }
      })
      .catch((e: AxiosError) => {
        toast('warn', 'Alerta', messageRequestError(e));
      })
      .finally(() => {
        setIsLoad(false);
      });
  };

  const savePurchaseStatus = async (item: Partial<PurchaseStatus>) => {
    setIsLoad(true);
    await api
      .post('/purchase/status', { data: item })
      .then(({ data }) => {
        if (data) {
          toast('success', 'Sucesso', 'Registro salvo com sucesso');
          clearAll();
        }
      })
      .catch((e: AxiosError) => {
        toast('warn', 'Alerta', messageRequestError(e));
      })
      .finally(() => {
        setIsLoad(false);
      });
  };

  const updatePurchaseStatus = async (item: PurchaseStatus) => {
    setIsLoad(true);
    await api
      .put('/purchase/status', {
        data: { id: item.id, pos: item.pos, title: item.title },
      })
      .then(({ data }) => {
        if (data) {
          toast('success', 'Sucesso', 'Registro editado com sucesso');
        }
      })
      .catch((e: AxiosError) => {
        toast('warn', 'Alerta', messageRequestError(e));
      })
      .finally(() => {
        setIsLoad(false);
      });
  };

  const deletePurchaseStatus = async (id: number) => {
    setIsLoad(true);
    await api
      .delete(`/purchase/status/${id}`)
      .then(({ data }) => {
        if (data) {
          toast('success', 'Sucesso', 'Registro removido com sucesso');
          loadPurchaseStatus(emptyPagination);
        }
      })
      .catch((e: AxiosError) => {
        toast('warn', 'Alerta', messageRequestError(e));
      })
      .finally(() => {
        setIsLoad(false);
      });
  };

  const handleSelectPurchaseStatus = (item: DataTableSelectParams) => {
    if (item.data) {
      setItem(item.data);
    }
  };

  const clearAll = () => {
    setItem(emptyPurchaseStatus);
  };

  const handleSubmit = async (item: PurchaseStatus) => {
    try {
      setIsLoad(true);
      formRef.current?.setErrors({});

      const validation = Yup.object({
        pos: Yup.number().required('Informe uma posição'),
        title: Yup.string().required('Informe um título para o status'),
      });

      await validation.validate(item, { abortEarly: false });

      if (item.id === 0) {
        await savePurchaseStatus({ pos: item.pos, title: item.title });
      } else {
        await updatePurchaseStatus(item);
      }

      await loadPurchaseStatus(emptyPagination);
    } catch (e: any) {
      if (e instanceof AxiosError) {
        if (e.response?.data?.message === 'Validation failed') {
          toast('warn', 'Alerta', e.response?.data.validation.body.message);
        }
      }

      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e);
        formRef.current?.setErrors(errors);
        toast('error', 'Error', e.errors[0]);
      } else {
        toast('error', 'Error', e.response?.data?.error);
      }
    } finally {
      setIsLoad(false);
    }
  };

  useEffect(() => {
    Promise.all([loadPurchaseStatus(emptyPagination)]);
  }, []);

  return (
    <SideBar
      visible={isOpen}
      onHide={() => onRequestClose()}
      position="right"
      style={{ width: '50vw' }}
    >
      <h1>Status da compra</h1>
      <Form
        ref={formRef}
        onSubmit={d => handleSubmit(item)}
        className="p-fluid grid formgrid"
        placeholder={''}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
      >
        <div className="field col-3">
          <label htmlFor="pos">Posição</label>
          <InputNumber
            name="pos"
            value={item.pos}
            onChange={e => setItem({ ...item, pos: Number(e.value) })}
            placeholder="Ex.: 999"
            minFractionDigits={0}
            maxFractionDigits={0}
          />
        </div>
        <div className="field col-9">
          <label htmlFor="title">Título</label>
          <InputText
            name="title"
            value={item.title}
            onChange={e => setItem({ ...item, title: e.currentTarget.value })}
            placeholder="Ex.: Digitação"
          />
        </div>
        <Divider />
        <div className="field col-6">
          <Button
            label="Limpar"
            type="reset"
            icon="pi pi-times"
            onClick={e => clearAll()}
            className="p-button-danger"
            loading={isLoad}
          />
        </div>

        <div className="field col-6">
          <Button
            label={item.id === 0 ? 'Salvar' : 'Editar'}
            icon="pi pi-check"
            className="p-button-success"
            type="submit"
            loading={isLoad}
            onClick={() => formRef.current?.submitForm()}
          />
        </div>
      </Form>
      <Divider />

      <DataTable
        value={items}
        responsiveLayout="scroll"
        paginator
        rows={10}
        rowsPerPageOptions={[10, 50, 200]}
        size="small"
        emptyMessage="Status não informados..."
        selectionMode="single"
        onRowSelect={e => handleSelectPurchaseStatus(e)}
        className="field col-12 p-0"
        loading={isLoad}
      >
        <Column
          header="#"
          className="my-0 py-1"
          body={renderColumnPosition}
          align={'center'}
          sortable
        />
        <Column
          header="Posição"
          field="pos"
          align={'left'}
          className="my-0 py-1"
        />
        <Column
          header="Título"
          field="title"
          align={'center'}
          className="my-0 py-1"
        />
        <Column
          header="Status"
          field="status"
          align={'center'}
          body={row => renderColumnStatus(row)}
          className="my-0 py-1"
        />
        <Column
          header="*"
          body={row => (
            <Button
              icon="fa-solid fa-xmark"
              className="p-button-danger p-button-rounded"
              onClick={() => deletePurchaseStatus(row.id)}
              disabled={row.status !== EnumStatus.ATIVO}
            />
          )}
          align={'center'}
          className="my-0 py-1"
        />
      </DataTable>
    </SideBar>
  );
};

export default SidebarPurchaseStatus;
