import IOptionsDTO from '../../../pages/business/dtos/IOptionsDTO';

export const icons: IOptionsDTO[] = [
  { value: 'fa-solid fa-file', label: 'file' },
  { value: 'fa-solid fa-file-pdf', label: 'file-pdf' },
  { value: 'fa-solid fa-file-word', label: 'file-word' },
  { value: 'fa-solid fa-file-excel', label: 'file-excel' },
  { value: 'fa-solid fa-file-powerpoint', label: 'file-powerpoint' },
  { value: 'fa-solid fa-file-image', label: 'file-image' },
  { value: 'fa-solid fa-file-audio', label: 'file-audio' },
  { value: 'fa-solid fa-file-video', label: 'file-video' },
  { value: 'fa-solid fa-file-code', label: 'file-code' },
  { value: 'fa-solid fa-file-archive', label: 'file-archive' },
  { value: 'fa-solid fa-file-contract', label: 'file-contract' },
  { value: 'fa-solid fa-file-csv', label: 'file-csv' },
  { value: 'fa-solid fa-file-download', label: 'file-download' },
  { value: 'fa-solid fa-file-export', label: 'file-export' },
  { value: 'fa-solid fa-file-import', label: 'file-import' },
  { value: 'fa-solid fa-file-invoice', label: 'file-invoice' },
  { value: 'fa-solid fa-file-invoice-dollar', label: 'file-invoice-dollar' },
  { value: 'fa-solid fa-file-medical', label: 'file-medical' },
  { value: 'fa-solid fa-file-prescription', label: 'file-prescription' },
  { value: 'fa-solid fa-file-signature', label: 'file-signature' },
  { value: 'fa-solid fa-file-upload', label: 'file-upload' },
  { value: 'fa-solid fa-folder', label: 'folder' },
  { value: 'fa-solid fa-folder-open', label: 'folder-open' },
  { value: 'fa-solid fa-folder-plus', label: 'folder-plus' },
  { value: 'fa-solid fa-folder-minus', label: 'folder-minus' },
  { value: 'fa-solid fa-folder-tree', label: 'folder-tree' },
  { value: 'fa-solid fa-copy', label: 'copy' },
  { value: 'fa-solid fa-paste', label: 'paste' },
  { value: 'fa-solid fa-cut', label: 'cut' },
  { value: 'fa-solid fa-save', label: 'save' },
  { value: 'fa-solid fa-trash', label: 'trash' },
  { value: 'fa-solid fa-trash-alt', label: 'trash-alt' },
  { value: 'fa-solid fa-trash-restore', label: 'trash-restore' },
  { value: 'fa-solid fa-trash-restore-alt', label: 'trash-restore-alt' },
  { value: 'fa-solid fa-print', label: 'print' },
  { value: 'fa-solid fa-undo', label: 'undo' },
  { value: 'fa-solid fa-redo', label: 'redo' },
  { value: 'fa-solid fa-search', label: 'search' },
  { value: 'fa-solid fa-search-plus', label: 'search-plus' },
  { value: 'fa-solid fa-search-minus', label: 'search-minus' },
  { value: 'fa-solid fa-filter', label: 'filter' },
  { value: 'fa-solid fa-sort', label: 'sort' },
  { value: 'fa-solid fa-sort-up', label: 'sort-up' },
  { value: 'fa-solid fa-sort-down', label: 'sort-down' },
  { value: 'fa-solid fa-sort-alpha-up', label: 'sort-alpha-up' },
  { value: 'fa-solid fa-sort-alpha-down', label: 'sort-alpha-down' },
  { value: 'fa-solid fa-sort-numeric-up', label: 'sort-numeric-up' },
  { value: 'fa-solid fa-sort-numeric-down', label: 'sort-numeric-down' },
  { value: 'fa-solid fa-check', label: 'check' },
  { value: 'fa-solid fa-check-circle', label: 'check-circle' },
  { value: 'fa-solid fa-check-square', label: 'check-square' },
  { value: 'fa-solid fa-times', label: 'times' },
  { value: 'fa-solid fa-times-circle', label: 'times-circle' },
  { value: 'fa-solid fa-exclamation', label: 'exclamation' },
  { value: 'fa-solid fa-exclamation-circle', label: 'exclamation-circle' },
  { value: 'fa-solid fa-exclamation-triangle', label: 'exclamation-triangle' },
  { value: 'fa-solid fa-question', label: 'question' },
  { value: 'fa-solid fa-question-circle', label: 'question-circle' },
  { value: 'fa-solid fa-info', label: 'info' },
  { value: 'fa-solid fa-info-circle', label: 'info-circle' },
  { value: 'fa-solid fa-ban', label: 'ban' },
  { value: 'fa-solid fa-lock', label: 'lock' },
  { value: 'fa-solid fa-unlock', label: 'unlock' },
  { value: 'fa-solid fa-shield-alt', label: 'shield-alt' },
  { value: 'fa-solid fa-shield-check', label: 'shield-check' },
  { value: 'fa-solid fa-shield-virus', label: 'shield-virus' },
  { value: 'fa-solid fa-user', label: 'user' },
  { value: 'fa-solid fa-user-plus', label: 'user-plus' },
  { value: 'fa-solid fa-user-minus', label: 'user-minus' },
  { value: 'fa-solid fa-user-check', label: 'user-check' },
  { value: 'fa-solid fa-user-times', label: 'user-times' },
  { value: 'fa-solid fa-user-lock', label: 'user-lock' },
  { value: 'fa-solid fa-user-shield', label: 'user-shield' },
  { value: 'fa-solid fa-user-tag', label: 'user-tag' },
  { value: 'fa-solid fa-user-edit', label: 'user-edit' },
  { value: 'fa-solid fa-user-cog', label: 'user-cog' },
  { value: 'fa-solid fa-users', label: 'users' },
  { value: 'fa-solid fa-users-cog', label: 'users-cog' },
  { value: 'fa-solid fa-address-book', label: 'address-book' },
  { value: 'fa-solid fa-address-card', label: 'address-card' },
  { value: 'fa-solid fa-id-card', label: 'id-card' },
  { value: 'fa-solid fa-id-card-alt', label: 'id-card-alt' },
  { value: 'fa-solid fa-passport', label: 'passport' },
  { value: 'fa-solid fa-credit-card', label: 'credit-card' },
  { value: 'fa-solid fa-credit-card-alt', label: 'credit-card-alt' },
  { value: 'fa-solid fa-money-bill', label: 'money-bill' },
  { value: 'fa-solid fa-money-bill-alt', label: 'money-bill-alt' },
  { value: 'fa-solid fa-money-check', label: 'money-check' },
  { value: 'fa-solid fa-money-check-alt', label: 'money-check-alt' },
  { value: 'fa-solid fa-coins', label: 'coins' },
  { value: 'fa-solid fa-piggy-bank', label: 'piggy-bank' },
  { value: 'fa-solid fa-wallet', label: 'wallet' },
  { value: 'fa-solid fa-chart-line', label: 'chart-line' },
  { value: 'fa-solid fa-chart-bar', label: 'chart-bar' },
  { value: 'fa-solid fa-chart-pie', label: 'chart-pie' },
  { value: 'fa-solid fa-chart-area', label: 'chart-area' },
  { value: 'fa-solid fa-chart-line-down', label: 'chart-line-down' },
  { value: 'fa-solid fa-chart-bar-alt', label: 'chart-bar-alt' },
  { value: 'fa-solid fa-chart-pie-alt', label: 'chart-pie-alt' },
  { value: 'fa-solid fa-chart-area-alt', label: 'chart-area-alt' },
  { value: 'fa-solid fa-tachometer-alt', label: 'tachometer-alt' },
  {
    value: 'fa-solid fa-tachometer-alt-average',
    label: 'tachometer-alt-average',
  },
  { value: 'fa-solid fa-tachometer-alt-fast', label: 'tachometer-alt-fast' },
  { value: 'fa-solid fa-tachometer-alt-slow', label: 'tachometer-alt-slow' },
  {
    value: 'fa-solid fa-tachometer-alt-fastest',
    label: 'tachometer-alt-fastest',
  },
  {
    value: 'fa-solid fa-tachometer-alt-slowest',
    label: 'tachometer-alt-slowest',
  },
  { value: 'fa-brands fa-facebook', label: 'facebook' },
  { value: 'fa-brands fa-facebook-f', label: 'facebook-f' },
  { value: 'fa-brands fa-facebook-messenger', label: 'facebook-messenger' },
  { value: 'fa-brands fa-facebook-square', label: 'facebook-square' },
  { value: 'fa-brands fa-twitter', label: 'twitter' },
  { value: 'fa-brands fa-twitter-square', label: 'twitter-square' },
  { value: 'fa-brands fa-instagram', label: 'instagram' },
  { value: 'fa-brands fa-instagram-square', label: 'instagram-square' },
  { value: 'fa-brands fa-whatsapp', label: 'whatsapp' },
  { value: 'fa-brands fa-whatsapp-square', label: 'whatsapp-square' },
  { value: 'fa-brands fa-linkedin', label: 'linkedin' },
  { value: 'fa-brands fa-linkedin-in', label: 'linkedin-in' },
  { value: 'fa-brands fa-youtube', label: 'youtube' },
  { value: 'fa-brands fa-youtube-square', label: 'youtube-square' },
  { value: 'fa-brands fa-tiktok', label: 'tiktok' },
  { value: 'fa-brands fa-tiktok-square', label: 'tiktok-square' },
  { value: 'fa-brands fa-google', label: 'google' },
  { value: 'fa-brands fa-google-drive', label: 'google-drive' },
  { value: 'fa-brands fa-google-play', label: 'google-play' },
  { value: 'fa-brands fa-google-plus', label: 'google-plus' },
  { value: 'fa-brands fa-google-plus-g', label: 'google-plus-g' },
  { value: 'fa-brands fa-google-plus-square', label: 'google-plus-square' },
  { value: 'fa-brands fa-google-wallet', label: 'google-wallet' },
  { value: 'fa-brands fa-apple', label: 'apple' },
  { value: 'fa-brands fa-apple-pay', label: 'apple-pay' },
  { value: 'fa-brands fa-apple-alt', label: 'apple-alt' },
  { value: 'fa-brands fa-apple-whole', label: 'apple-whole' },
  { value: 'fa-brands fa-microsoft', label: 'microsoft' },
  { value: 'fa-brands fa-windows', label: 'windows' },
  { value: 'fa-brands fa-amazon', label: 'amazon' },
  { value: 'fa-brands fa-aws', label: 'aws' },
  { value: 'fa-brands fa-github', label: 'github' },
  { value: 'fa-brands fa-github-alt', label: 'github-alt' },
  { value: 'fa-brands fa-github-square', label: 'github-square' },
  { value: 'fa-brands fa-gitlab', label: 'gitlab' },
  { value: 'fa-brands fa-bitbucket', label: 'bitbucket' },
  { value: 'fa-brands fa-paypal', label: 'paypal' },
  { value: 'fa-brands fa-paypal-square', label: 'paypal-square' },
  { value: 'fa-brands fa-stripe', label: 'stripe' },
  { value: 'fa-brands fa-stripe-s', label: 'stripe-s' },
  { value: 'fa-brands fa-cc-visa', label: 'cc-visa' },
  { value: 'fa-brands fa-cc-mastercard', label: 'cc-mastercard' },
  { value: 'fa-brands fa-cc-amex', label: 'cc-amex' },
  { value: 'fa-brands fa-cc-paypal', label: 'cc-paypal' },
  { value: 'fa-brands fa-cc-stripe', label: 'cc-stripe' },
  { value: 'fa-brands fa-cc-discover', label: 'cc-discover' },
  { value: 'fa-brands fa-cc-diners-club', label: 'cc-diners-club' },
  { value: 'fa-brands fa-cc-jcb', label: 'cc-jcb' },
  { value: 'fa-brands fa-cc-apple-pay', label: 'cc-apple-pay' },
  { value: 'fa-brands fa-cc-amazon-pay', label: 'cc-amazon-pay' },
  { value: 'fa-brands fa-cc-google-pay', label: 'cc-google-pay' },
  { value: 'fa-brands fa-cc-paypal', label: 'cc-paypal' },
  { value: 'fa-brands fa-cc-stripe', label: 'cc-stripe' },
  { value: 'fa-brands fa-cc-visa', label: 'cc-visa' },
  { value: 'fa-brands fa-cc-mastercard', label: 'cc-mastercard' },
  { value: 'fa-brands fa-cc-amex', label: 'cc-amex' },
  { value: 'fa-brands fa-cc-discover', label: 'cc-discover' },
  { value: 'fa-brands fa-cc-diners-club', label: 'cc-diners-club' },
  { value: 'fa-brands fa-cc-jcb', label: 'cc-jcb' },
  { value: 'fa-brands fa-cc-apple-pay', label: 'cc-apple-pay' },
  { value: 'fa-brands fa-cc-amazon-pay', label: 'cc-amazon-pay' },
  { value: 'fa-brands fa-cc-google-pay', label: 'cc-google-pay' },
  { value: 'fa-brands fa-cc-paypal', label: 'cc-paypal' },
  { value: 'fa-brands fa-cc-stripe', label: 'cc-stripe' },
  { value: 'fa-brands fa-cc-visa', label: 'cc-visa' },
  { value: 'fa-brands fa-cc-mastercard', label: 'cc-mastercard' },
  { value: 'fa-brands fa-cc-amex', label: 'cc-amex' },
  { value: 'fa-brands fa-cc-discover', label: 'cc-discover' },
  { value: 'fa-brands fa-cc-diners-club', label: 'cc-diners-club' },
  { value: 'fa-brands fa-cc-jcb', label: 'cc-jcb' },
  { value: 'fa-brands fa-cc-apple-pay', label: 'cc-apple-pay' },
  { value: 'fa-brands fa-cc-amazon-pay', label: 'cc-amazon-pay' },
  { value: 'fa-brands fa-cc-google-pay', label: 'cc-google-pay' },
  { value: 'fa-brands fa-cc-paypal', label: 'cc-paypal' },
  { value: 'fa-brands fa-cc-stripe', label: 'cc-stripe' },
  { value: 'fa-brands fa-cc-visa', label: 'cc-visa' },
  { value: 'fa-brands fa-cc-mastercard', label: 'cc-mastercard' },
  { value: 'fa-brands fa-cc-amex', label: 'cc-amex' },
  { value: 'fa-brands fa-cc-discover', label: 'cc-discover' },
  { value: 'fa-brands fa-cc-diners-club', label: 'cc-diners-club' },
  { value: 'fa-brands fa-cc-jcb', label: 'cc-jcb' },
  { value: 'fa-brands fa-cc-apple-pay', label: 'cc-apple-pay' },
  { value: 'fa-brands fa-cc-amazon-pay', label: 'cc-amazon-pay' },
  { value: 'fa-brands fa-cc-google-pay', label: 'cc-google-pay' },
  { value: 'fa-brands fa-cc-paypal', label: 'cc-paypal' },
  { value: 'fa-brands fa-cc-stripe', label: 'cc-stripe' },
  { value: 'fa-brands fa-cc-visa', label: 'cc-visa' },
  { value: 'fa-brands fa-cc-mastercard', label: 'cc-mastercard' },
  { value: 'fa-brands fa-cc-amex', label: 'cc-amex' },
  { value: 'fa-brands fa-cc-discover', label: 'cc-discover' },
  { value: 'fa-brands fa-cc-diners-club', label: 'cc-diners-club' },
  { value: 'fa-brands fa-cc-jcb', label: 'cc-jcb' },
  { value: 'fa-brands fa-cc-apple-pay', label: 'cc-apple-pay' },
  { value: 'fa-brands fa-cc-amazon-pay', label: 'cc-amazon-pay' },
  { value: 'fa-brands fa-cc-google-pay', label: 'cc-google-pay' },
  { value: 'fa-brands fa-cc-paypal', label: 'cc-paypal' },
  { value: 'fa-brands fa-cc-stripe', label: 'cc-stripe' },
  { value: 'fa-brands fa-cc-visa', label: 'cc-visa' },
  { value: 'fa-brands fa-cc-mastercard', label: 'cc-mastercard' },
  { value: 'fa-brands fa-cc-amex', label: 'cc-amex' },
  { value: 'fa-brands fa-cc-discover', label: 'cc-discover' },
  { value: 'fa-brands fa-cc-diners-club', label: 'cc-diners-club' },
  { value: 'fa-brands fa-cc-jcb', label: 'cc-jcb' },
  { value: 'fa-brands fa-cc-apple-pay', label: 'cc-apple-pay' },
  { value: 'fa-brands fa-cc-amazon-pay', label: 'cc-amazon-pay' },
  { value: 'fa-brands fa-cc-google-pay', label: 'cc-google-pay' },
  { value: 'fa-brands fa-cc-paypal', label: 'cc-paypal' },
  { value: 'fa-brands fa-cc-stripe', label: 'cc-stripe' },
  { value: 'fa-brands fa-cc-visa', label: 'cc-visa' },
  { value: 'fa-brands fa-cc-mastercard', label: 'cc-mastercard' },
  { value: 'fa-brands fa-cc-amex', label: 'cc-amex' },
  { value: 'fa-brands fa-cc-discover', label: 'cc-discover' },
  { value: 'fa-brands fa-cc-diners-club', label: 'cc-diners-club' },
  { value: 'fa-brands fa-cc-jcb', label: 'cc-jcb' },
  { value: 'fa-brands fa-cc-apple-pay', label: 'cc-apple-pay' },
  { value: 'fa-brands fa-cc-amazon-pay', label: 'cc-amazon-pay' },
  { value: 'fa-brands fa-cc-google-pay', label: 'cc-google-pay' },
  { value: 'fa-brands fa-cc-paypal', label: 'cc-paypal' },
  { value: 'fa-brands fa-cc-stripe', label: 'cc-stripe' },
  { value: 'fa-brands fa-cc-visa', label: 'cc-visa' },
  { value: 'fa-brands fa-cc-mastercard', label: 'cc-mastercard' },
  { value: 'fa-brands fa-cc-amex', label: 'cc-amex' },
  { value: 'fa-brands fa-cc-discover', label: 'cc-discover' },
  { value: 'fa-brands fa-cc-diners-club', label: 'cc-diners-club' },
  { value: 'fa-brands fa-cc-jcb', label: 'cc-jcb' },
  { value: 'fa-brands fa-cc-apple-pay', label: 'cc-apple-pay' },
  { value: 'fa-brands fa-cc-amazon-pay', label: 'cc-amazon-pay' },
  { value: 'fa-brands fa-cc-google-pay', label: 'cc-google-pay' },
  { value: 'fa-brands fa-cc-paypal', label: 'cc-paypal' },
  { value: 'fa-brands fa-cc-stripe', label: 'cc-stripe' },
  { value: 'fa-brands fa-cc-visa', label: 'cc-visa' },
  { value: 'fa-brands fa-cc-mastercard', label: 'cc-mastercard' },
  { value: 'fa-brands fa-cc-amex', label: 'cc-amex' },
  { value: 'fa-brands fa-cc-discover', label: 'cc-discover' },
  { value: 'fa-brands fa-cc-diners-club', label: 'cc-diners-club' },
  { value: 'fa-brands fa-cc-jcb', label: 'cc-jcb' },
  { value: 'fa-brands fa-cc-apple-pay', label: 'cc-apple-pay' },
  { value: 'fa-brands fa-cc-amazon-pay', label: 'cc-amazon-pay' },
  { value: 'fa-brands fa-cc-google-pay', label: 'cc-google-pay' },
  { value: 'fa-brands fa-cc-paypal', label: 'cc-paypal' },
  { value: 'fa-brands fa-cc-stripe', label: 'cc-stripe' },
  { value: 'fa-brands fa-cc-visa', label: 'cc-visa' },
  { value: 'fa-brands fa-cc-mastercard', label: 'cc-mastercard' },
  { value: 'fa-brands fa-cc-amex', label: 'cc-amex' },
  { value: 'fa-brands fa-cc-discover', label: 'cc-discover' },
  { value: 'fa-brands fa-cc-diners-club', label: 'cc-diners-club' },
  { value: 'fa-brands fa-cc-jcb', label: 'cc-jcb' },
  { value: 'fa-brands fa-cc-apple-pay', label: 'cc-apple-pay' },
  { value: 'fa-brands fa-cc-amazon-pay', label: 'cc-amazon-pay' },
  { value: 'fa-brands fa-cc-google-pay', label: 'cc-google-pay' },
  { value: 'fa-brands fa-cc-paypal', label: 'cc-paypal' },
  { value: 'fa-brands fa-cc-stripe', label: 'cc-stripe' },
  { value: 'fa-brands fa-cc-visa', label: 'cc-visa' },
  { value: 'fa-brands fa-cc-mastercard', label: 'cc-mastercard' },
  { value: 'fa-brands fa-cc-amex', label: 'cc-amex' },
  { value: 'fa-brands fa-cc-discover', label: 'cc-discover' },
  { value: 'fa-brands fa-cc-diners-club', label: 'cc-diners-club' },
  { value: 'fa-brands fa-cc-jcb', label: 'cc-jcb' },
  { value: 'fa-brands fa-cc-apple-pay', label: 'cc-apple-pay' },
  { value: 'fa-brands fa-cc-amazon-pay', label: 'cc-amazon-pay' },
  { value: 'fa-brands fa-cc-google-pay', label: 'cc-google-pay' },
  { value: 'fa-brands fa-cc-paypal', label: 'cc-paypal' },
  { value: 'fa-brands fa-cc-stripe', label: 'cc-stripe' },
  { value: 'fa-brands fa-cc-visa', label: 'cc-visa' },
  { value: 'fa-brands fa-cc-mastercard', label: 'cc-mastercard' },
  { value: 'fa-brands fa-cc-amex', label: 'cc-amex' },
  { value: 'fa-brands fa-cc-discover', label: 'cc-discover' },
  { value: 'fa-brands fa-cc-diners-club', label: 'cc-diners-club' },
  { value: 'fa-brands fa-cc-jcb', label: 'cc-jcb' },
  { value: 'fa-brands fa-cc-apple-pay', label: 'cc-apple-pay' },
  { value: 'fa-brands fa-cc-amazon-pay', label: 'cc-amazon-pay' },
  { value: 'fa-brands fa-cc-google-pay', label: 'cc-google-pay' },
  { value: 'fa-brands fa-cc-paypal', label: 'cc-paypal' },
  { value: 'fa-brands fa-cc-stripe', label: 'cc-stripe' },
  { value: 'fa-brands fa-cc-visa', label: 'cc-visa' },
  { value: 'fa-brands fa-cc-mastercard', label: 'cc-mastercard' },
  { value: 'fa-brands fa-cc-amex', label: 'cc-amex' },
  { value: 'fa-brands fa-cc-discover', label: 'cc-discover' },
  { value: 'fa-brands fa-cc-diners-club', label: 'cc-diners-club' },
  { value: 'fa-brands fa-cc-jcb', label: 'cc-jcb' },
  { value: 'fa-brands fa-cc-apple-pay', label: 'cc-apple-pay' },
  { value: 'fa-brands fa-cc-amazon-pay', label: 'cc-amazon-pay' },
  { value: 'fa-brands fa-cc-google-pay', label: 'cc-google-pay' },
  { value: 'fa-brands fa-cc-paypal', label: 'cc-paypal' },
  { value: 'fa-brands fa-cc-stripe', label: 'cc-stripe' },
  { value: 'fa-brands fa-cc-visa', label: 'cc-visa' },
  { value: 'fa-brands fa-cc-mastercard', label: 'cc-mastercard' },
  { value: 'fa-brands fa-cc-amex', label: 'cc-amex' },
  { value: 'fa-brands fa-cc-discover', label: 'cc-discover' },
  { value: 'fa-brands fa-cc-diners-club', label: 'cc-diners-club' },
  { value: 'fa-brands fa-cc-jcb', label: 'cc-jcb' },
  { value: 'fa-brands fa-cc-apple-pay', label: 'cc-apple-pay' },
  { value: 'fa-brands fa-cc-amazon-pay', label: 'cc-amazon-pay' },
  { value: 'fa-brands fa-cc-google-pay', label: 'cc-google-pay' },
  { value: 'fa-brands fa-cc-paypal', label: 'cc-paypal' },
  { value: 'fa-brands fa-cc-stripe', label: 'cc-stripe' },
  { value: 'fa-brands fa-cc-visa', label: 'cc-visa' },
  { value: 'fa-brands fa-cc-mastercard', label: 'cc-mastercard' },
  { value: 'fa-brands fa-cc-amex', label: 'cc-amex' },
  { value: 'fa-brands fa-cc-discover', label: 'cc-discover' },
  { value: 'fa-brands fa-cc-diners-club', label: 'cc-diners-club' },
  { value: 'fa-brands fa-cc-jcb', label: 'cc-jcb' },
  { value: 'fa-brands fa-cc-apple-pay', label: 'cc-apple-pay' },
  { value: 'fa-brands fa-cc-amazon-pay', label: 'cc-amazon-pay' },
  { value: 'fa-brands fa-cc-google-pay', label: 'cc-google-pay' },
  { value: 'fa-brands fa-cc-paypal', label: 'cc-paypal' },
  { value: 'fa-brands fa-cc-stripe', label: 'cc-stripe' },
  { value: 'fa-brands fa-cc-visa', label: 'cc-visa' },
  { value: 'fa-brands fa-cc-mastercard', label: 'cc-mastercard' },
  { value: 'fa-brands fa-cc-amex', label: 'cc-amex' },
  { value: 'fa-brands fa-cc-discover', label: 'cc-discover' },
  { value: 'fa-brands fa-cc-diners-club', label: 'cc-diners-club' },
  { value: 'fa-brands fa-cc-jcb', label: 'cc-jcb' },
  { value: 'fa-brands fa-cc-apple-pay', label: 'cc-apple-pay' },
  { value: 'fa-brands fa-cc-amazon-pay', label: 'cc-amazon-pay' },
  { value: 'fa-brands fa-cc-google-pay', label: 'cc-google-pay' },
  { value: 'fa-brands fa-cc-paypal', label: 'cc-paypal' },
  { value: 'fa-brands fa-cc-stripe', label: 'cc-stripe' },
  { value: 'fa-brands fa-cc-visa', label: 'cc-visa' },
  { value: 'fa-brands fa-cc-mastercard', label: 'cc-mastercard' },
  { value: 'fa-brands fa-cc-amex', label: 'cc-amex' },
  { value: 'fa-brands fa-cc-discover', label: 'cc-discover' },
  { value: 'fa-brands fa-cc-diners-club', label: 'cc-diners-club' },
  { value: 'fa-brands fa-cc-jcb', label: 'cc-jcb' },
  { value: 'fa-brands fa-cc-apple-pay', label: 'cc-apple-pay' },
  { value: 'fa-brands fa-cc-amazon-pay', label: 'cc-amazon-pay' },
  { value: 'fa-brands fa-cc-google-pay', label: 'cc-google-pay' },
  { value: 'fa-brands fa-cc-paypal', label: 'cc-paypal' },
  { value: 'fa-brands fa-cc-stripe', label: 'cc-stripe' },
  { value: 'fa-brands fa-cc-visa', label: 'cc-visa' },
  { value: 'fa-brands fa-cc-mastercard', label: 'cc-mastercard' },
  { value: 'fa-brands fa-cc-amex', label: 'cc-amex' },
  { value: 'fa-brands fa-cc-discover', label: 'cc-discover' },
  { value: 'fa-brands fa-cc-diners-club', label: 'cc-diners-club' },
  { value: 'fa-brands fa-cc-jcb', label: 'cc-jcb' },
  { value: 'fa-brands fa-cc-apple-pay', label: 'cc-apple-pay' },
  { value: 'fa-brands fa-cc-amazon-pay', label: 'cc-amazon-pay' },
  { value: 'fa-brands fa-cc-google-pay', label: 'cc-google-pay' },
  { value: 'fa-brands fa-cc-paypal', label: 'cc-paypal' },
  { value: 'fa-brands fa-cc-stripe', label: 'cc-stripe' },
  { value: 'fa-brands fa-cc-visa', label: 'cc-visa' },
  { value: 'fa-brands fa-cc-mastercard', label: 'cc-mastercard' },
  { value: 'fa-brands fa-cc-amex', label: 'cc-amex' },
  { value: 'fa-brands fa-cc-discover', label: 'cc-discover' },
  { value: 'fa-brands fa-cc-diners-club', label: 'cc-diners-club' },
  { value: 'fa-brands fa-cc-jcb', label: 'cc-jcb' },
  { value: 'fa-brands fa-cc-apple-pay', label: 'cc-apple-pay' },
  { value: 'fa-brands fa-cc-amazon-pay', label: 'cc-amazon-pay' },
  { value: 'fa-brands fa-cc-google-pay', label: 'cc-google-pay' },
  { value: 'fa-brands fa-cc-paypal', label: 'cc-paypal' },
  { value: 'fa-brands fa-cc-stripe', label: 'cc-stripe' },
  { value: 'fa-brands fa-cc-visa', label: 'cc-visa' },
  { value: 'fa-brands fa-cc-mastercard', label: 'cc-mastercard' },
  { value: 'fa-brands fa-cc-amex', label: 'cc-amex' },
  { value: 'fa-brands fa-cc-discover', label: 'cc-discover' },
  { value: 'fa-brands fa-cc-diners-club', label: 'cc-diners-club' },
  { value: 'fa-brands fa-cc-jcb', label: 'cc-jcb' },
  { value: 'fa-brands fa-cc-apple-pay', label: 'cc-apple-pay' },
  { value: 'fa-brands fa-cc-amazon-pay', label: 'cc-amazon-pay' },
  { value: 'fa-brands fa-cc-google-pay', label: 'cc-google-pay' },
  { value: 'fa-brands fa-cc-paypal', label: 'cc-paypal' },
  { value: 'fa-brands fa-cc-stripe', label: 'cc-stripe' },
];
