import { useEffect, useRef, useState } from 'react';
import PurchaseHeaderButtons from '../header-buttons';
import Progress from '../../../components/progress-bar';
import { Form } from '@unform/web';
import { emptyPurchaseSearch, PurchaseSearch } from '../../../types/purchase';
import { emptyPagination, IPagination } from '../../../types/pagination';
import api from '../../../services/api';
import { DataTable } from '../../../components/DataTable';
import { DataTableSelectParams } from 'primereact/datatable';
import { Column } from 'primereact/column';
import renderColumnPosition from '../../../components/DataTableColumns/RenderColumnPosition';
import { InputText } from '../../../components/Inputs/InputText';
import CalendarRange from '../../../components/Inputs/CalendarRange';
import { MultiSelect } from '../../../components/Inputs/InputMultSelect';
import { Button } from 'primereact/button';
import { endOfMonth, startOfMonth } from 'date-fns';
import IOptionsDTO from '../../business/dtos/IOptionsDTO';
import useToastContext from '../../../hooks/toast';
import messageRequestError from '../../../utils/messageRequestError';
import renderColumnStatus from '../../../components/DataTableColumns/RenderColumnStatus';
import renderColumnDate from '../../../components/DataTableColumns/RenderColumnDate';
import renderColumnDecimal from '../../../components/DataTableColumns/RenderColumnDecimal';
import { EnumStatus } from '../../../enum/EnumStatus';
import MenuPopUp from '../../../components/MenuPopPup';
import { FormHandles } from '@unform/core';
import ClearName from '../../../utils/ClearName';
import { Tag } from 'primereact/tag';
import SidebarPurchaseStatus from '../sidebar-purchase-status';
import { v4 } from 'uuid';
import SidebarPurchaseAllow from '../sidebar-purchase-allow';
import { AxiosError } from 'axios';
import { useHistory } from 'react-router-dom';
import openReports from '../../../utils/openReports';

type DataSearch = {
  keyword: string | undefined;
  period: Date[];
  status: string[];
  status_id: string[];
};

const emptyDataSearch: DataSearch = {
  keyword: undefined,
  period: [startOfMonth(new Date()), endOfMonth(new Date())],
  status: ['A', 'C'],
  status_id: [],
};

const PurchaseList = () => {
  const formRef = useRef<FormHandles>(null);
  const navigate = useHistory();
  const toast = useToastContext();

  const [isLoad, setIsLoad] = useState(false);
  const [sidebarPurchaseAllowVisible, setSidebarPurchaseAllowVisible] =
    useState(false);
  const [purchase, setPurchase] = useState<PurchaseSearch>(emptyPurchaseSearch);
  const [purchases, setPurchases] = useState<PurchaseSearch[]>([]);

  const [dataSearch, setDataSearch] = useState<DataSearch>(emptyDataSearch);
  const [statusIds, setStatusIds] = useState<IOptionsDTO[]>([]);

  const loadStatus = async (pagination: IPagination) => {
    setIsLoad(true);
    await api
      .post('/purchase/status/list', { pagination })
      .then(({ data }) => {
        if (data) {
          setStatusIds(
            data.map((i: { id: any; title: any }) => {
              return {
                value: i.id,
                label: i.title,
              };
            }),
          );
        }
      })
      .catch((e: any) => {
        toast('warn', 'Alerta', messageRequestError(e));
      })
      .finally(() => {
        setIsLoad(false);
      });
  };

  const searchPurchases = async (dataSearch: DataSearch) => {
    setIsLoad(true);
    await api
      .post('/purchase/search', {
        data: { ...dataSearch },
      })
      .then(({ data }) => {
        if (data) {
          setPurchases(data);
        }
      })
      .catch((e: any) => {
        toast('warn', 'Alerta', messageRequestError(e));
      })
      .finally(() => {
        setIsLoad(false);
      });
  };

  const cancelPurchase = async (id: string) => {
    setIsLoad(true);
    await api
      .delete(`/purchase/${id}`)
      .then(({ data }) => {
        if (data) {
          toast('success', 'Sucesso', 'Registro cancelado com sucesso');
          searchPurchases(emptyDataSearch);
        }
      })
      .catch((e: AxiosError) => {
        toast('warn', 'Alerta', messageRequestError(e));
      })
      .finally(() => {
        setIsLoad(false);
      });
  };

  const handleRowSelect = (item: PurchaseSearch) => {
    if (item.status !== EnumStatus.CANCELADO) {
      setPurchase(item);
      setSidebarPurchaseAllowVisible(true);
    }
  };

  const printPurchase = async (id: string) => {
    setIsLoad(true);
    await api
      .get(`/reports/purchase/${id}`)
      .then(({ data }) => {
        if (data) {
          openReports(
            `${process.env.REACT_APP_SERVER_URL}/files/pdf/${data}`,
            'ordem-compra',
          );
        }
      })
      .catch((e: AxiosError) => {
        toast('warn', 'Alerta', messageRequestError(e));
      })
      .finally(() => {
        setIsLoad(false);
      });
  };

  const mountMenu = (item: PurchaseSearch) => {
    const optionsMenu: any[] = [];

    if (!item.allowed && item.status !== EnumStatus.CANCELADO) {
      optionsMenu.push({
        label: 'Editar',
        icon: 'fa-solid fa-file-edit',
        command: () => navigate.push(`/purchase/update/${item.id}`),
      });
    }

    if (!item.allowed) {
      optionsMenu.push({
        label: 'Autorizar',
        icon: 'fa-solid fa-check',
        command: () => handleRowSelect(item),
      });
    }

    if (item.allowed && item.status === EnumStatus.ATIVO) {
      optionsMenu.push({
        label: 'Entrada no estoque',
        icon: 'fa-solid fa-box',
        command: () => {},
      });
    }

    optionsMenu.push({
      label: 'Imprimir',
      icon: 'fa-solid fa-print',
      command: () => printPurchase(item.id),
    });

    if (item.status === EnumStatus.ATIVO) {
      optionsMenu.push({
        label: 'Cancelar',
        icon: 'fa-solid fa-xmark',
        command: () => cancelPurchase(item.id),
      });
    }

    return optionsMenu;
  };

  const renderColumnMenu = (item: PurchaseSearch) => {
    const options = mountMenu(item);
    return (
      <>
        <MenuPopUp model={options} rowData={item} setData={() => {}} />
      </>
    );
  };

  const renderColumnAutorizado = (item: PurchaseSearch) => {
    return item.allowed ? (
      <>
        <i className="fa-solid fa-flag text-green-500 mr-2"></i>
        {ClearName(item.allow_user)}
      </>
    ) : (
      <i className="fa-solid fa-flag text-gray-400"></i>
    );
  };

  useEffect(() => {
    Promise.all([
      searchPurchases(emptyDataSearch),
      loadStatus(emptyPagination),
    ]);
  }, [, sidebarPurchaseAllowVisible]);

  return (
    <>
      <PurchaseHeaderButtons />
      <Progress isLoad={isLoad} />
      <div className="card">
        <Form
          ref={formRef}
          onSubmit={() => searchPurchases(dataSearch)}
          className="p-fluid grid formgroup"
        >
          <div className="field col-4">
            <label htmlFor="keyword">Buscar</label>
            <InputText
              name="keyword"
              value={dataSearch.keyword}
              onChange={e =>
                setDataSearch({ ...dataSearch, keyword: e.currentTarget.value })
              }
              placeholder="Ex.: Fornecedor Acme Ltda"
            />
          </div>
          <div className="field col-2">
            <label htmlFor="period">Emissão</label>
            <CalendarRange
              name="period"
              value={dataSearch.period}
              onChange={e =>
                setDataSearch({ ...dataSearch, period: e.value as Date[] })
              }
            />
          </div>
          <div className="field col-2">
            <label htmlFor="status_id">
              Situação {` [${dataSearch.status_id.length}]`}
            </label>
            <MultiSelect
              name="status_id"
              value={dataSearch.status_id}
              onChange={e =>
                setDataSearch({ ...dataSearch, status_id: e.value })
              }
              options={statusIds}
              placeholder="Selecione..."
              display="chip"
            />
          </div>
          <div className="field col-2">
            <label htmlFor="status">
              Status {` [${dataSearch.status.length}]`}
            </label>
            <MultiSelect
              name="status"
              value={dataSearch.status}
              onChange={e => setDataSearch({ ...dataSearch, status: e.value })}
              options={[
                { value: 'A', label: 'Ativo' },
                { value: 'C', label: 'Cancelado' },
              ]}
              placeholder="Selecione..."
              display="chip"
            />
          </div>
          <div className="field col-2 flex align-items-end justify-content-center">
            <Button
              label="Buscar"
              type="submit"
              loading={isLoad}
              className="p-button-info w-full"
              icon="fa-solid fa-search"
              onClick={e => searchPurchases(dataSearch)}
            />
          </div>
        </Form>

        <DataTable
          value={purchases}
          responsiveLayout="scroll"
          paginator
          rows={10}
          rowsPerPageOptions={[10, 50, 200]}
          size="small"
          emptyMessage="Produtos não informados..."
          selectionMode="single"
          className="field col-12 p-0"
        >
          <Column
            header="#"
            className="my-0 py-1"
            body={renderColumnPosition}
            align={'center'}
            sortable
          />
          <Column
            header="Código"
            field="codigo"
            className="my-0 py-1"
            align={'center'}
            sortable
          />
          <Column
            header="Fornecedor"
            field="provider_name"
            className="my-0 py-1"
            align={'left'}
            sortable
          />
          <Column
            header="Autorizado"
            field="allowed"
            body={row => renderColumnAutorizado(row)}
            className="my-0 py-1"
            align={'center'}
          />
          <Column
            header="Abertura"
            field="created_at"
            body={row => renderColumnDate(row.created_at)}
            className="my-0 py-1"
            align={'center'}
          />
          <Column
            header="Prev. Receb."
            field="delivery_date"
            body={row => renderColumnDate(row.delivery_date)}
            className="my-0 py-1"
            align={'center'}
          />
          <Column
            header="Situação"
            field="status_title"
            body={row => (
              <Tag value={row.status_title} severity="info" className="px-3" />
            )}
            className="my-0 py-1"
            align={'center'}
          />
          <Column
            header="Total"
            field="total"
            body={row => renderColumnDecimal(row.total)}
            className="my-0 py-1"
            align={'center'}
          />
          <Column
            header="Status"
            field="status"
            body={row => renderColumnStatus(row)}
            className="my-0 py-1"
            align={'center'}
          />
          <Column
            header="*"
            body={row => renderColumnMenu(row)}
            className="my-0 py-1"
            align={'center'}
          />
        </DataTable>
      </div>
      <SidebarPurchaseAllow
        key={v4()}
        purchase={purchase}
        isOpen={sidebarPurchaseAllowVisible}
        onRequestClose={() => {
          setSidebarPurchaseAllowVisible(false);
          setPurchase(emptyPurchaseSearch);
        }}
      />
    </>
  );
};

export default PurchaseList;
