import { v4 } from 'uuid';
import { EnumStatus } from '../../enum/EnumStatus';
import { IProductPriceSearch } from '../../pages/product/dtos/IProductDTO';
import { emptyProductPriceSearch } from '../../pages/order/types/EmptyProductPriceSearch';
import { addDays } from 'date-fns';
import { Provider } from '../person';

export type Purchase = {
  id: string; // ok
  codigo: number; // ok
  allowed: boolean;
  allow_user_id: string;
  allow_obs: string;
  provider_id: string; // ok
  provider?: Provider;
  delivery_date: Date; // ok
  delivered: boolean;
  total_on: number; // ok
  total_off: number; // ok
  total: number; // ok
  days_sugest: number; // ok
  purchaseItems: PurchaseItem[];
  purchaseAttachs: PurchaseAttach[];
  status_id: number; // ok
  status: EnumStatus; // ok
  created_at: Date; // ok
};

export type PurchaseSearch = {
  id: string;
  codigo: number;
  allowed: boolean;
  allow_user_id: string;
  allow_user: string;
  allow_obs: string;
  provider_id: string;
  provider_name: string;
  delivery_date: Date;
  delivered: boolean;
  total: number;
  status_id: string;
  status_title: string;
  status: string;
  created_at: Date;
};

export const emptyPurchaseSearch: PurchaseSearch = {
  id: v4(),
  codigo: 0,
  allowed: false,
  allow_user_id: '',
  allow_obs: '',
  provider_id: '',
  provider_name: '',
  delivery_date: new Date(),
  delivered: false,
  total: 0,
  status_id: '',
  status_title: '',
  status: '',
  created_at: new Date(),
};

export const emptyPurchase: Purchase = {
  id: v4(),
  codigo: 0,
  allowed: false,
  allow_user_id: '',
  allow_obs: '',
  provider_id: '',
  delivery_date: addDays(new Date(), 30),
  delivered: false,
  total_on: 0,
  total_off: 0,
  total: 0,
  days_sugest: 0,
  purchaseItems: [],
  purchaseAttachs: [],
  status_id: 0,
  status: EnumStatus.ATIVO,
  created_at: new Date(),
};

export type PurchaseItem = {
  id: number;
  purchase_id: string;
  product_id: string; // 4 col
  product: IProductPriceSearch;
  unity: string; // 1 col
  qnt: number; // 1 col
  vlr: number; // 1 col
  add: number; // 1 col
  desc: number; // 1 col
  total: number; // 1 col
  able: boolean;
  status: EnumStatus;
  user_id: string;
  value?: string;
  label?: string;
};

export const emptyPurchaseItem: PurchaseItem = {
  id: 0,
  purchase_id: '',
  product_id: '',
  product: emptyProductPriceSearch,
  unity: '',
  qnt: 0,
  vlr: 0,
  add: 0,
  desc: 0,
  total: 0,
  able: false,
  status: EnumStatus.ATIVO,
  user_id: '',
};

export type PurchaseAttach = {
  id: number;
  purchase_id: string;
  file_name: string;
  user_id: string;
};

export const emptyPurchaseAttach: PurchaseAttach = {
  id: 0,
  purchase_id: '',
  file_name: '',
  user_id: '',
};

export type PurchaseStatus = {
  id: number;
  pos: number;
  title: string;
};

export const emptyPurchaseStatus: PurchaseStatus = {
  id: 0,
  pos: 0,
  title: '',
};
